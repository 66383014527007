import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '../../../auth/auth';
import ErrorModal from '../../../components/ErrorModal';
import { AUTH_REDIRECT_KEY } from '../../../utils/constants';
// import {
//   mxTrackEvent,
//   currentCountryUsingIp,
//   capitalizeFirstLetter,
// } from '../../../utils/utils';

const createCandidate = async () => {
  try {
    const parsedProfileData = JSON.parse(localStorage.getItem('profile'));
    const {
      given_name: givenName,
      family_name: familyName,
      firstname,
      lastname,
    } = parsedProfileData;

    let firstName = '';
    let lastName = '';
    if (!givenName && !familyName) {
      firstName = firstname || 'User';
      lastName = lastname || '';
    } else {
      firstName = givenName || 'User';
      lastName = familyName || '';
    }
    const postData = {
      name: `${firstName} ${lastName}`,
      email: parsedProfileData.email,
      metadata: {},
      source: 'self-signup',
    };
    const dbReq = await fetch('/api/create/newcandidate', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(postData),
    });

    if (!dbReq.ok) {
      console.log('Created candidate');
    } else {
      console.log('Created failed');
    }
  } catch (e) {
    console.log('Create interview failed', e);
  }
};


const Callback = () => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [isNotVerified, setIsNotVerified] = useState(false);

  const history = useHistory();

  const handleAuthentication = async () => {
    try {
      await Auth.handleAuthentication();
      const to = sessionStorage.getItem(AUTH_REDIRECT_KEY);
      await createCandidate();
      sessionStorage.clear();
      history.replace(to || '/candidate');
    } catch (e) {
      const { errorDescription } = e;
      if (errorDescription === 'Blocked email.') {
        setIsBlocked(true);
      }
      if (errorDescription === 'Please verify your email before logging in.') {
        setIsNotVerified(true);
      }
    }
  };

  const getLoggedInCountry = async () => {
    console.log('Getting  country');
    // const loggedInCountry = capitalizeFirstLetter(await currentCountryUsingIp());
    console.log('Candidate login');
    // mxTrackEvent('Candidate login', {
    //   loggedInCountry,
    // });
  };

  const handleModalClose = () => {
    Auth.signOut();
  };

  useEffect(() => {
    handleAuthentication();
    getLoggedInCountry();
  }, []); // eslint-disable-line

  if (isBlocked) {
    return (
      <ErrorModal
        show
        handleClose={handleModalClose}
        errorText="Blocked !!!"
        subError="You are not allowed to access this platform"
      />
    );
  }

  if (isNotVerified) {
    return (
      <ErrorModal
        show
        handleClose={handleModalClose}
        errorText="Email verification required"
        subError={`Please check your inbox for an email from us and verify your email address.
          You can come back once email is verfied..
          If you did not receive the email, please check your spam folder or request a new verification email.`}
      />
    );
  }

  return (
    <div />
  );
};


export default Callback;
