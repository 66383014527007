export default {
  helloApplicant: '您好申请人，',
  thankYou: '谢谢。',
  question: '问题',
  start: '开始',
  submitting: '正在提交',
  submit: '提交',
  sending: '发送',
  proceed: '继续',
  close: '关闭',
  poweredBy: '供电',
  contactUs: '联系我们',
  showMore: '显示更多',
  showLess: '显示较少',
  applying: '正在申请',
  apply: '申请',
  click: '点击',
  done: '完毕',
  uploading: '上传中',
  cantFindPage: '我们找不到您要查找的页面。',
  usefulLinks: '以下是一些可能有用的链接',
  menuHome: '家',
  menuAboutUs: '关于我们',
  menuTnC: '条款和条件',
  menuPrivacy: '隐私',
  tnc1: '选中此框即表示您同意 ',
  tnc2: 'Interviewer.AI 候选人使用条款',
  amInterested: '我有兴趣',
  second: '第二',
  second_plural: '秒',
  secondWithCount: '{{count}} 第二',
  secondWithCount_plural: '{{count}} 秒',
  minute: '分钟',
  minute_plural: '分钟',
  minuteWithCount: '{{count}} 分钟',
  minuteWithCount_plural: '{{count}} 分钟',
  workmapScreen: {
    greeting: '亲爱的 {{name}},',
    unansweredQuestions: '您未回答的问题是：',
    description:
      '您被邀请参加针对以下角色的工作图（技能）评估： {{jobName}} 和 {{companyName}}。',
    instruction: '操作说明',
    instruction1: '请根据您大部分时间的工作情况来回答每项陈述。',
    instruction2: '在回答这些陈述时，请选择反映您一般想法或感受的选项。',
    instruction3:
      '此评估中没有正确或错误的答案，因此不要在任何一个答案上花费太多时间。',
    instruction4: '保持自发性，尽量不要根据您认为我们想听到的内容来回答。',
    instruction5: '诚实地回答。',
    instruction6: '此评估大约需要 5 分钟才能完成。',
    goBack: '回去',
    skillsAssessment: '技能评估',
    of: '的',
    answers: '答案',
    questions: '问题',
    submit: '提交',
    start: '开始',
    instruction7: '您仍有未解答的问题。',
    stronglyDisagree: '强烈不同意',
    disagree: '不同意',
    somewhatDisagree: '有点不同意',
    neitherAgreeNorDisagree: '既不同意也不不同意',
    somewhatAgree: '有点同意',
    agree: '同意',
    stronglyAgree: '强烈同意',
    aiaInstruction1: '',
    aiaInstruction2: '',
    aiaInstruction3: '',
  },
  scale: {
    'Not at all': '一点也不',
    Rarely: '很少',
    Sometimes: '有时',
    Often: '经常',
    'Very Often': '经常',
    'Strongly Disagree': '强烈不同意',
    'Somewhat Disagree': '有点不同意',
    Disagree: '不同意',
    'Neither Agree nor Disagree': '既不同意也不不同意',
    Neutral: '中性的',
    Agree: '同意',
    'Somewhat Agree': '有点同意',
    'Strongly Agree': '强烈同意',
  },
  applySendSuccessful: {
    thankyou: '感谢您的提交！',
    instruction: '我们已向您发送了一封电子邮件，其中包含采访页面的链接。',
    instruction1: '',
    instruction2: '',
    instruction3: '',
  },
  applySuccessful: {
    congrats: '嘿， {{name}}！',
    submitted: '感谢您提供的信息。',
    whatsNext: '什么是新的 ？',
    instruction: '申请流程的下一步是录制面试问题的视频回答。',
    coachingInstruction: '作为在线评估的一部分，您需要录制对评估问题的视频回答',
    instruction1: '选择一个安静的地方并确保音频和灯光清晰',
    instruction2: '在开始之前检查是否有任何连接问题',
    instruction3: '自信且声音足够大，以便很好地捕捉到音频',
    instruction4: '视频采访应在合理的时间内完成。',
    instruction5: '我们期待您的视频回复。',
    giveBestShot: '这是您展示个性、热情和活力的机会，所以请全力以赴！',
    helpfulTips: '有用的提示：',
    doWithin48: '48 小时内完成 - 将链接发送给我',
    wsgClientInstruction1:
      '5. 请注意，WSG 将在提交给雇主之前筛选所有录制的视频。',
    doNow: '现在开始',
    infoProvide: '感谢您提供的信息',
    opportunityText: '这是您展示个性、热情和活力的机会，所以请全力以赴！',
    quietSpot: '找到一个好的空间',
    strongNetwork: '强大的网络',
    confidentLoud: '说清楚',
    videoCompleted: '意见书',
  },
  applySuccessfulDelayInterview: {
    thankyou: '谢谢 {{name}}！',
    submitted: '您的申请已提交。',
    instruction:
      '我们的团队将审核您的申请，如果您入围下一轮，我们会尽快与您联系。',
    goodLuck: '祝你好运！',
  },
  candidateProfile: {
    intro: '自我介绍一下',
    instruction: ' 在进行视频面试之前，我们要求您花点时间介绍一下自己。',
    placeholder: '告诉我们你自己',
  },
  browser: {
    worksBest: 'Interviewer.AI 目前在 Chrome 和 Firefox 中效果最佳。',
    bestExperience: '我们正在努力确保每个人都有最好的面试体验！',
    meantime:
      '同时，安装最新版本的 Google Chrome 或 Mozilla Firefox 并访问与您的面试相同的面试链接：',
    downloadChrome: '下载 Chrome',
    downloadFirefox: '下载火狐浏览器',
    lookForward: '我们期待您的回复！',
    bestRegards: '此致，',
    interviewerTeam: '面试官.AI团队',
    scanQrCode: '扫描二维码在手机上录制',
  },
  digitalProfile: {
    interviews: '采访',
    interviewName: '面试姓名',
    status: '地位',
    dateResponded: '回复日期',
    summary: '概括',
    topSkills: '顶级技能',
    viewResume: '查看简历',
    visitLinkedin: '访问领英',
    myWorkmapScore: '我的工作图分数',
  },
  organisationIntroVideoScreen: {
    continue: '继续',
    note: '（注：观看完整视频后将启用继续按钮）',
  },
  endedScreen: {
    recordAgain: '再次录制',
    recordAgainQuestion: '您确定要再次录制吗？',
    recordAgainDescription:
      '请注意，这将要求您从问题 1 开始重新进行整个采访。按“再次录制”按钮重新开始，或者您可以关闭此窗口并继续提交您的回答。',
    thankyou: '谢谢 {{name}}！',
    coachingThankyou: '谢谢 {{name}}！',
    instruction1: '您可以先查看上面的回复',
    instruction2: ' 使用页面底部的按钮提交它们。',
    getFeedback: '为了帮助我们改进我们的产品，我们感谢您在下面提供反馈！',
    feedback: '反馈',
    placeholder: '非常感谢您的反馈！',
    rateExp: '评价您的面试经历',
    Hi: '嘿！',
    checkVideo: '让我们检查一下您的视频。',
    question: '问题',
    submit: '提交',
  },
  thankyouEndScreen: {
    radioText1: '对这份工作不感兴趣',
    radioText2: '对视频面试不感兴趣',
    radioText3: '稍后会做',
    radioText4: '无法提交',
    radioText5: '其他的',
    pleaseChooseReason: '请选择一个原因',
    stateYourReason: '请说出你的理由',
    notFinished: '您还没有完成采访。',
    notSubmittedVideoResp: '您的视频回复尚未提交。',
    noSubmittion: '没有提交！',
    shareYourFeedback: '请分享您的反馈。',
    tellUsQuittingInterview: '如果您要退出面试或面临任何挑战，请告诉我们。',
    ratingStarsMandatory: '评级星级是强制性的',
    pleaseDontLeaveField: '请不要将此字段留空',
    congratulations: '恭喜你',
    responseSent: '您的视频回复已成功发送。',
    instruction1: '等待您收到成功提交消息，因为您的申请可能正在处理中',
    instruction2: '我们尽力在几分钟内评估您的回复。',
    instruction3: '评估后您将收到一封电子邮件。',
    instruction4:
      '如有任何其他问题，您可以通过 support@interviewer.ai 联系我们。',
    finishedInterview: '您已完成采访。',
    shareExperience: '分享一下你的录音经验。',
    submit: '提交',
    thankyou: '谢谢',
    feedbackText: '为了帮助我们改进我们的产品，我们感谢您在下面提供反馈！',
    feedback: '反馈',
  },
  interviewCompleted: {
    submitted: '您已提交对采访的回复。',
    thankyou: '感谢您的回复！',
  },
  interviewFailure: {
    errorMessage: '我们注意到您访问的链接无效或不存在。',
    title: '糟糕，采访链接好像失效了！',
    contactUsBody:
      '无法访问视频采访链接&body=嗨，我无法访问采访链接： {{- link }}。',
  },
  interviewSignUp: {
    name: '姓名',
    apply: '立即申请',
    submit: '提交',
    experience: '经验',
    seniorityLevel: '资历级别',
    employmentType: '就业类型',
    roleOverview: '概述',
    companyName: '公司',
    namePlaceholder: '你的名字',
    applicationForm: '申请表',
    required: '必需的',
    email: '电子邮件',
    emailPlaceholder: 'your_email@example.com',
    school: '学校',
    phone: '电话',
    linkedin: '领英个人资料',
    facebook: '脸书简介',
    identityProof: '识别证据',
    identityTypes: '（护照/驾驶执照/政府颁发的身份证件）',
    identityFormats: '接受的格式：jpg 最大文件大小：2MB',
    instagram: 'Instagram 个人资料',
    resume: '简历（仅限 pdf、doc 和 docx）',
    errorExpired: '糟糕，采访链接似乎已过期！',
    errorMessage: '我们注意到您访问的链接不再可用。',
    contactUsBody:
      '无法访问视频采访链接&body=嗨，我无法访问采访链接： {{- link }}。',
    toApplyFor: '申请',
    coachingToApplyFor: '要继续进行评估，请提交以下详细信息：',
    submitInfo: ' ，请在下面提交您的信息：',
    selectFile: '选择文件',
    supportPDF: '仅支持 PDF、doc 和 docx',
    dragPDF: '将您的文件拖至此处或单击',
    browse: '浏览',
    your: '你的',
    prefilledNameNote:
      '我们发现您的个人资料与此电子邮件关联，因此我们已为您预先填写。',
    IP: {
      programOfStudy: '学习计划',
      school: '学校',
      formTitle: 'InsightPro在线模拟视频面试报名',
      instruction1:
        '1. 完成注册后，您可以选择立即开始视频面试或稍后通过电子邮件提供的链接完成。',
      instruction2: '2. 面试包括 8 个行为问题，持续约 15 分钟。',
      instruction3:
        '3.面试结束后3个工作日内，您的面试评估报告将发送至您注册的邮箱。',
      instruction4:
        '* 请注意，不完整或无效的面试将不会被审核，因此不会提供评估结果。',
      toApplyFor: '申请',
      at: '在',
      howYouHear: '您是如何得知此活动的？',
      indicateIndustry: '（请注明您有兴趣从事什么行业和职位。）',
      yearOfGrad: '毕业年份',
      careerInterests: '职业兴趣',
      submitInfo: ' ，请在下面提交您的信息：',
      linkedin: '社交媒体 - 领英',
      wechat: '社交媒体 - 微信',
      friend: '朋友',
      infoBooth: '信息亭',
      searchEngine: '搜索引擎',
    },
    sopDocument: '个人陈述（SOP）文件',
  },
  interviewSignUpSideScreen: {
    processHeadingWithWorkmapEnabled: '3步流程',
    processHeadingWithoutWorkmapEnabled: '2步过程',
    fillApplication: '填写申请表',
    completeAssessment: '完整评估',
    videoResponse: '录制视频回复',
  },
  multiAttempts: {
    title: '哦不，该链接仅一次性访问有效！',
    errorMessage: '我们注意到您访问的链接不再可用。',
    contactUsBody:
      '采访链接仅限一次性访问&body=您好，我无法访问采访链接： {{- link }}。',
  },
  preQuestionReview: {
    title: '这是测试题的录音',
    pleaseNote: '请注意：',
    description1: '虽然该视频不会提交，但这就是您的视频的显示方式 ',
    description2: '。',
    employer: '雇主',
    warning: '注意：一旦开始面试，您将无法返回或重新开始。',
    coachingWarning: '注意：一旦开始评估，您将无法返回或重新开始。',
    instruction1: '当你开始面试时，你只需',
    coachingInstruction1: '当您开始评估时，您只需',
    instruction2: '阅读问题并准备答案。',
    prepTime: '{{prepTime}} 秒 ',
    troubleShootRedirection: '支持链接以协助和解决摄像头/麦克风问题 ',
    clickLink: '点击这里',
  },
  interview: {
    testAgain: '再次测试',
    startInterview: '开始面试',
    recordResponse: '记录回应',
    goodLuck: '祝你好运！',
    interview: '价值',
    '10seconds': ' 你有 10 秒的时间回答这个问题。',
    allowDevices: '您需要允许浏览器访问摄像头和麦克风。',
    done: '完毕',
    preparation: '准备',
    testQuestion: '这是一道测试题',
    remainingTime: '剩余时间',
    back: '后退',
    checkYourVideo: '检查您的视频',
    okayLetsProceed: '好的，我们继续',
    startPractice: '开始练习',
    importantNotes: '重要提示：',
    instruction1: '首先，用练习题进行测试',
    instruction2: '它不会被视为提交',
    instruction3: '单击开始测试开始',
    startTest: '开始测试',
    alreadyApplied: '已经申请了！',
    alreadyAppliedToTheJob: '您已经使用以下方式申请了该职位',
    clickBelowToProceed: '单击下面继续。',
    continue: '继续',
    blockerHeading: '您需要打开麦克风和视频权限才能继续。',
    blockerPrimaryText: '要打开麦克风和视频权限，请按照以下步骤操作。',
    forChrome: '对于 Chrome：',
    chromeBlockerStep1: '1) 单击右上角的更多。',
    chromeBlockerStep2: '2) 单击隐私和安全站点设置。',
    forFireFox: '对于火狐浏览器：',
    firefoxBlockerStep1: '1) 单击菜单按钮并选择设置。',
    firefoxBlockerStep2: '2) 单击左侧菜单中的隐私和安全。',
    firefoxBlockerStep3: '3) 向下滚动到权限部分。',
    firefoxBlockerStep4: '4) 单击麦克风和视频选项的设置按钮',
    questions: '问题',
  },
  preQuestion: {
    qtitle: '告诉我们您最喜欢的颜色以及为什么？',
    title1: '这是一个',
    title2: ' 练习题 ',
    title3: '测试您的视频和音频。',
    notSubmitted: '它不会添加到您提交的内容中。',
    toTest: '测试',
    instruction1: ' 一旦您对录音体验感到满意，请单击',
    instruction2: '位于底部以继续。',
    warning: '注意：您只有 1 次机会参加本次面试。',
    coachingWarning: '注意：您只有 1 次机会进行此实际评估。',
    '10seconds': ' 你有 10 秒的时间回答这个问题。',
    allowDevices: '您需要允许浏览器访问摄像头和麦克风。',
    numOfQuestions: '总共有 {{numberOfQuestions}} 这次采访中的问题。',
    troubleShootRedirection: '支持链接以协助和解决摄像头/麦克风问题 ',
    clickLink: '点击这里',
  },
  recording: {
    howManySeconds: '你有 {{seconds}} 秒回答这个问题。',
    recordingStatus: '记录',
    preparationStatus: '准备',
  },
  submitFailure: {
    ohNo: '哦不！',
    description: '',
    instruction1: '不幸的是，我们无法成功捕获您录制的答案。',
    instruction2: '请花点时间重新记录您对这些问题的回答。',
    instruction3:
      '如果您在重新提交后仍然面临挑战，请随时通过以下方式与我们联系： ',
    instruction4:
      '您还可以通过扫描下面的二维码在其他设备上重新记录您对这些问题的回答。',
    recordAgain: '再次录制',
    networkStatus: '网络状态',
    connected: ' 已连接',
    notConnected: ' 未连接',
    check1: '检查您是否 ',
    check2: '可以在这里访问互联网。',
    reSubmit: '重新提交',
  },
  submittedScreen: {
    title: '您的回复已提交！',
    thankyou: '感谢您抽出宝贵的时间接受我们的采访，希望您享受这次体验！',
    redirect1: '您现在将被重定向到 {{time}} 秒...',
    orClick: '或点击 ',
    here: '这里',
    ifNotRedirected: ' 如果您没有被重定向',
    redirectToPortal: '登录/注册候选人门户。',
    clickHere: '点击这里',
  },
  submittingScreen: {
    title: '我们正在提交您的回复，请不要关闭您的浏览器...',
  },
  landing: {
    dear: '亲爱的 {{name}},',
    hi: '你好 {{name}},',
    previous: '以前的',
    next: '下一个',
    description: '作为在线面试的一部分，您需要完成以下职位的视频面试',
    coachingDescription: '作为在线评估的一部分，您需要完成以下职位的视频面试',
    with: '和 ',
    at: '在',
    quietSpot: '找一个光线充足、WiFi 信号强的安静地点进行评估。',
    instructions: '指示',
    instruction1: '该视频评估包括',
    instruction1a: '问题，应该带你',
    instruction1b: '完成，具体取决于分配给您的问题数量。',
    instruction2: '您需要在一次会议内完成评估。',
    instruction3: '一旦您提出第一个问题，自动计时器就会启动。 ',
    instruction3a: '，然后是每个问题可能有所不同的录音时间限制。',
    instruction4: '请记住在准备或记录您的回答时跟踪计时器。',
    instruction5:
      '请注意：面试开始后请勿刷新、关闭或退出页面，因为您将无法再访问面试链接。',
    coachingInstruction5:
      '请注意：评估开始后，请勿刷新、关闭或退出页面，因为您将无法再访问评估链接。',
    instruction6: '不用担心，您将能够在整个评估结束时查看您的视频回答。',
    instructionRedo:
      '* 但是，问题的顺序和性质可能会发生变化，以确保您在视频面试过程中表现得自然、自发。',
    envCheck: '设备/环境检查',
    env1: '找一个光线充足、WiFi 信号强的安静地点进行评估。',
    env2: '采访录音目前仅适用于 Chrome 和 Firefox。',
    env3: '为了进行视频采访，我们需要使用您的摄像机和麦克风。',
    coachingEnv3: '为了继续进行视频评估，我们将需要访问您的摄像机和麦克风。',
    env4: '您将看到一个练习题，您可以在开始实际评估之前测试您的音频和视频质量。',
    env5: '当您提出答案时，请务必将自己摆在屏幕框架内，坐直并直视相机。',
    env6: '在整个评估结束时，您将能够查看您的视频回答。',
    readyClick: '准备好后，请单击“继续”按钮。',
    resumeOnly: '简历（仅限 pdf、doc 和 docx）*',
    error: '请仅上传 pdf 文件。',
    env7: '录制时请勿在浏览器之间切换。',
    env8: '在记录您的回答时，保持目光接触，不要过多分散注意力。',
    env9: '录音时音频会被拾取。',
    instruction7: '请按照规定上传正确的身份证明文件（政府身份证等）',
  },
  resumeParser: {
    title: '恢复解析器演示',
    description: '在下面上传简历，看看我们的系统如何解析和评估候选人的简历',
    dragResume: '将简历拖放到此处，或单击选择文件（仅限 pdf）',
    parsedResults: '解析结果',
    experience: '经验',
    personalDetails: '个人资料',
    totalYoE: '总经验年数',
    company: '公司',
    position: '位置',
    duration: '期间',
    skills: '技能',
    education: '教育',
    institute: '研究所',
    degree: '程度',
    major: '主要的',
    score: '分数',
    overall: '全面的',
    betterFitNote:
      '注意：根据分配的职位描述和要求，简历总体得分为 55-100 的候选人更适合。',
    name: '姓名',
    email: '电子邮件',
    phone: '电话',
  },
  countdown: {
    recordIn: '记录于',
  },
  prepTimer: {
    timer: '准备时间',
  },
  candidateDetails: {
    back: '后退',
    download: '下载',
    questions: '问题',
    videoTranscript: '视频文字记录',
    resumeScore: '简历分数',
    workmapScore: '工作图分数',
    aiVideoScore: 'AI视频评分',
    videoAssessment: '视频面试评估',
    text: '文本',
    voice: '嗓音',
    facialExpression: '表情',
    professionalism: '专业精神',
    energyLevel: '能量水平',
    communication: '沟通',
    sociability: '社交性',
    pace: '步伐',
    charity: '明晰',
    sentiments: '情绪',
    energy: '活力',
    positivity: '积极性',
    positiveEmotion: '积极情绪',
    aboutThisAssessmentReport: '关于本评估报告',
    introduction: '介绍',
    useOfReport: '报告的使用',
    confidentialityDisclaimer: '保密与免责声明',
    workmapAssessment: '工作图评估',
    actualScore: '实际分数',
    resumeScoring: '恢复评分',
    excellent: '出色的',
    high: '高的',
    medium: '中等的',
    low: '低的',
    glossaryIndex: '术语表/索引',
    definitionOfWorkMapSkill: '工作地图技能的定义',
    definitionOfVideoInterviewParameter: '视频面试参数定义',
    candidateSummary: '候选人概要',
    notAvailable: '无法使用',
  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND': '请完成您的视频面试以查看评估。',
    '404_ASSESSMENT_DATA_NOT_READY': '目前正在评估您的视频面试。',
    '404_CANDIDATE_NOT_AVAILABLE': '您尚未接受任何视频采访。',
    default: '发生错误。',
  },
  companyDetails: {
    company: '公司',
    about: '关于',
    experience: '经验',
    apply: '申请',
    description: '描述',
    viewJobDetailText: '查看职位详情',
    readMore: '阅读更多',
    readLess: '少读书',
  },
  dashboardPanel: {
    dashboard: '仪表板',
    hey: '嘿',
    morningGreeting: '早上好',
    afternoonGreeting: '下午好',
    eveningGreeting: '晚上好',
    upgrade: '升级',
    support: '支持',
    signOut: '登出',
    collapse: '坍塌',
    faqs: '常见问题解答',
    company: '公司',
    role: '角色',
    status: '地位',
    action: '行动',
    date: '日期',
    recordNow: '立即录制',
    responded: '已回复',
    processing: '加工',
    viewDetails: '查看详情',
    personalInfo: '个人信息',
    introVideo: '介绍视频',
    score: '分数',
    email: '电子邮件',
    phone: '电话',
    summary: '概括',
    topSkill: '顶级技能',
    workmapScore: '工作图分数',
    aiVideoScore: 'AI视频评分',
    introText1: '准备好接受令人兴奋的见解。',
    introText2: '你的介绍视频。',
    recordVideo: '录制视频',
    settings: '设置',
    yourInterviews: '您的采访',
  },
  candidatePortalSettings: {
    settings: '设置',
    publicProfile: '公开资料',
    profileVisibility: '每个人都可以看到您的个人资料。',
    closeAccount: ' 关闭账户',
    primaryConfirmationText: '看到你离开我们感到很遗憾。',
    secondaryConfirmationText:
      '这将删除您的所有数据以及您可能已回复的任何模拟采访。',
    reasoningHeading: ' 告诉我们您要关闭帐户的原因：',
    primaryFeedbackText:
      '我们重视您的反馈，但请注意，我们无法回复在此提交的有关您帐户的评论。',
    clickHere: '点击这里',
    buttonSectionPrimarytext: '请确认您的帐户已关闭？',
    deleteButtonText: '是的，我是',
    cancelButtonText: '现在不要',
    modalConfirmtext:
      '此操作将导致您过去使用我们的平台提出的任何申请被删除/撤回。',
    confirm: ' 确认',
  },
  purchaseBanner: {
    heading: '升级即可查看详细的 AI 面试报告',
    subHeading: '对下一轮面试充满信心。',
    launchAt: '推出优惠于',
    only: '仅有的',
    year: '年',
  },
  additionalFormField: {
    chooseFileBtn: '选择文件',
    pleaseChooseAFileText: '请选择一个文件',
  },
  domainQuestion: {
    domainAssessment: '领域评估',
    of: '的',
    submit: '提交',
    questions: '问题',
    answers: '答案',
    submittedAssessment: '您已提交域评估。',
    autoRedirect: ' 3 秒后自动重定向到下一步...',
  },
  quitInterviewModal: {
    confirmQuit: '确认退出面试',
    areYouSure: '您确定要退出采访吗？',
    confirm: '确认',
    cancel: '取消',
  },
  processingVideo: {
    instruction1: '正在处理您的视频...',
    instruction2: '请稍等',
    instruction3: '只需要几秒钟...',
    instruction4: '要有耐心。',
    noAudioFound: '未找到音频，请使用清晰的音频重新录制。',
  },
  audioError: {
    message: '我们很遗憾转录您的视频时出现错误。',
  },
};
