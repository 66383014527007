export default {
  helloApplicant: 'Witaj wnioskodawcy',
  thankYou: 'Dziękuję Ci.',
  question: 'Pytanie',
  start: 'Początek',
  submitting: 'Przedkładający',
  submit: 'Zatwierdź',
  sending: 'Wysyłanie',
  proceed: 'Kontynuować',
  close: 'Blisko',
  poweredBy: 'Zasilany przez',
  contactUs: 'Skontaktuj się z nami',
  showMore: 'Pokaż więcej',
  showLess: 'Pokaż mniej',
  applying: 'Zastosowanie',
  apply: 'Zastosować',
  click: 'Kliknij',
  done: 'Gotowe',
  uploading: 'Przesyłanie',
  cantFindPage: 'Nie możemy znaleźć strony, której szukasz',
  usefulLinks: 'Oto kilka linków, które mogą być przydatne',
  menuHome: 'Dom',
  menuAboutUs: 'O Nas',
  menuTnC: 'Regulamin',
  menuPrivacy: 'Prywatność',
  tnc1: 'Aplikując, zgadzasz się z ',
  tnc2: 'Interviewer.AI kandydat warunki korzystania',
  amInterested: 'Jestem zainteresowany',
  second: 'sekundy',
  second_plural: 'sekundy',
  secondWithCount: '{{count}} sekundy',
  secondWithCount_plural: '{{count}} sekundy',
  minute: 'minuty',
  minute_plural: 'minuty',
  minuteWithCount: '{{count}} minuty',
  minuteWithCount_plural: '{{count}} minuty',
  workmapScreen: {
    greeting: 'Cześć {{name}},',
    unansweredQuestions: 'Twoje pytania bez odpowiedzi to:',
    description: 'Zapraszamy do wzięcia udziału w teście kompetencyjnym na stanowisko {{jobName}} w {{companyName}}.',
    instruction: 'Instrukcje',
    instruction1: 'Udzielając odpowiedzi, prosimy o odniesienie się do tego, jak zachowywałeś się przez większość czasu w środowisku zawodowym.',
    instruction2: 'Przeczytaj poniższe stwierdzenia i WYBIERZ opcję, która odzwierciedla Twój ogólny sposób myślenia lub odczuwania.',
    instruction3: 'W tej ocenie nie ma dobrych ani złych odpowiedzi. Dlatego nie spędzaj zbyt wiele czasu na jednym stwierdzeniu.',
    instruction4: 'Odpowiadaj spontanicznie i staraj się nie odpowiadać na podstawie „wyjątków od reguły”.',
    instruction5: 'Odpowiadaj uczciwie. Twoje odpowiedzi wpłyną na dokładność Twojej oceny i mogą być dalej weryfikowane za pomocą wywiadu wideo, gdy zostaniesz zakwalifikowany.',
    instruction6: 'Ta ocena powinna zająć około 5 minut. Kliknij przycisk „Start”, kiedy będziesz gotowy. Wszystkiego najlepszego!',
    instruction7: 'Nadal masz pytania bez odpowiedzi. Prosimy o wypełnienie wszystkich pytań przed przesłaniem.',
    stronglyDisagree: 'Kategorycznie się nie zgadzam',
    disagree: 'Nie zgadzać się',
    somewhatDisagree: 'Raczej się nie zgadzam',
    neitherAgreeNorDisagree: 'Ani się zgadzam, ani się nie zgadzam',
    somewhatAgree: 'Raczej się zgadzam',
    agree: 'Zgodzić się',
    stronglyAgree: 'Stanowczo się zgadzam',
  },
  scale: {
    'Not at all': 'Ani trochę',
    Rarely: 'Rzadko',
    Sometimes: 'Czasami',
    Often: 'Często',
    'Very Often': 'Bardzo Często',
    'Strongly Disagree': 'Kategorycznie się nie zgadzam',
    'Somewhat Disagree': 'Raczej się nie zgadzam',
    'Neither Agree nor Disagree': 'Ani zgadzam się, ani nie zgadzam',
    Disagree: 'Nie zgadzać się',
    Neutral: 'Neutralny',
    Agree: 'Zgodzić się',
    'Somewhat Agree': 'Raczej się zgadzam',
    'Strongly Agree': 'Stanowczo się zgadzam',
  },
  applySendSuccessful: {
    thankyou: 'Dziękujemy za aplikowanie!',
    instruction: 'Wysłaliśmy Ci wiadomość e-mail z linkiem do strony z rozmową kwalifikacyjną. Zalecamy nagrywanie w ciągu następnych 48 godzin, aby zmaksymalizować swoje szanse na zaproszenie do następnego etapu. Powodzenia!',
  },
  applySuccessful: {
    congrats: 'Gratulacje {{name}}!',
    submitted: 'Twoje odpowiedzi zostały przesłane.',
    whatsNext: 'Co dalej?',
    instruction: 'Kolejnym krokiem w rekrutacji na stanowisko {{jobName}} jest rozmowa wideo.',
    coachingInstruction: 'Następnym krokiem w procesie aplikacyjnym jest nagranie odpowiedzi wideo na pytanie testowe.',
    instruction1: '1. Wybierz spokojne miejsce i upewnij się, że wokół jest wystarczająco dużo światła',
    instruction2: '2. Upewnij się, że masz stabilne łącze internetowe.',
    instruction3: '3. Bądź pewny siebie i mów głośno i wyraźnie, abyśmy mogli Cię dobrze słyszeć.',
    instruction4: '4. Odpowiedzi można nagrać w dowolnym momencie przed upłynięciem terminu',
    instruction5: 'Czekamy na Twoje odpowiedzi wideo. Powodzenia!',
    giveBestShot: 'To Twoja okazja, aby pokazać swoją osobowość, pasję i energię, więc daj z siebie wszystko!',
    helpfulTips: 'Pomocne Wskazówki:',
    doWithin48: 'Zrób to w ciągu 48 godzin - wyślij mi link',
    doNow: 'Zrób to teraz',
    wsgClientInstruction1:
      '5. Należy pamiętać, że WSG sprawdzi wszystkie nagrane filmy przed przesłaniem ich pracodawcom. Filmy zawierające lub prezentujące nieodpowiednie lub nieprofesjonalne zachowanie, takie jak użycie obraźliwych słów, nieodpowiednie tło, nie będą przesyłane pracodawcom w celu rozpatrzenia podania o pracę. Kandydaci do pracy zostaną powiadomieni o konieczności poprawienia nagranego filmu i ponownego przesłania.',
    infoProvide: 'Dziękuję za przekazane informacje',
    opportunityText: 'To Twoja szansa na zaprezentowanie swojej osobowości, pasji i energii, więc daj z siebie wszystko!',
    quietSpot: 'Ciche miejsce i zapewniam',
    strongNetwork: 'Silna sieć',
    confidentLoud: 'Pewny i wystarczająco głośny',
    videoCompleted: 'Filmy można uzupełnić',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'Dzięki {{name}}!',
    submitted: 'Twoja aplikacja została złożona.',
    instruction: 'Nasz zespół sprawdzi Twoje zgłoszenie i wkrótce skontaktuje się z Tobą, jeśli zostaniesz zakwalifikowany do następnej rundy. Pamiętaj, że tylko kandydaci z krótkiej listy zostaną powiadomieni.',
    goodLuck: 'Życzę Ci szczęścia',
  },
  candidateProfile: {
    intro: 'Przedstaw się',
    instruction: 'Przed przystąpieniem do wywiadu wideo prosimy o napisanie opisu, aby się przedstawić',
    placeholder: 'Opowiedz nam o sobie',
  },
  digitalProfile: {
    interviews: 'Wywiady',
    interviewName: 'Nazwa wywiadu',
    status: 'Status',
    dateResponded: 'Data odpowiedzi',
    summary: 'Podsumowanie',
    topSkills: 'Najważniejsze umiejętności',
    viewResume: 'Wyświetl CV',
    visitLinkedin: 'Odwiedź Linkedin',
    myWorkmapScore: 'Wynik My Workmap',
  },
  organisationIntroVideoScreen: {
    continue: 'Kontynuować',
    note: '(Uwaga: przycisk Kontynuuj zostanie włączony po obejrzeniu całego filmu)',
  },
  browser: {
    worksBest: 'Interviewer.AI Cdziała najlepiej w Chrome i Firefox w tym momencie',
    bestExperience: 'Pracujemy nad tym, aby każdy miał jak najlepsze doświadczenie w przeprowadzaniu rozmów kwalifikacyjnych!',
    meantime: 'W międzyczasie zainstaluj najnowszą wersję przeglądarki Google Chrome lub Mozilla Firefox i uzyskaj dostęp do tego samego linku do rozmowy kwalifikacyjnej:',
    downloadChrome: ' Pobieranie Chrome',
    downloadFirefox: 'Pobieranie Firefox',
    lookForward: 'Czekamy na Twoją odpowiedź!',
    bestRegards: 'Z pozdrowieniami,',
    interviewerTeam: 'Interviewer.AI zespół',
    scanQrCode: 'Zeskanuj kod QR, aby nagrać na telefonie komórkowym',
  },
  endedScreen: {
    recordAgain: 'Nagraj Ponownie',
    recordAgainQuestion: 'Czy na pewno chcesz nagrywać ponownie?',
    recordAgainDescription: 'Zwróć uwagę, że będzie to wymagało ponownego przeprowadzenia całego wywiadu z pytania 1. Naciśnij przycisk Nagraj ponownie, aby wznowić, lub możesz zamknąć to okno i przystąpić do przesyłania odpowiedzi.',
    thankyou: 'Dzięki {{name}}! Skończyłeś wywiad.',
    coachingThankyou: 'Dzięki {{name}}! Zakończyłeś ocenę.',
    instruction1: 'Możesz wcześniej przejrzeć swoje odpowiedzi ',
    instruction2: 'przesyłając je za pomocą przycisku u dołu strony.',
    getFeedback: 'Aby pomóc nam ulepszyć nasz produkt, doceniamy Twoją opinię poniżej! Co myślisz o doświadczeniu związanym z nagraniem?',
    feedback: 'Sprzężenie zwrotne',
    placeholder: 'Twoja opinia jest bardzo mile widziana!',
    rateExp: 'Oceń swoje doświadczenie podczas rozmowy kwalifikacyjnej',
  },
  interviewCompleted: {
    submitted: 'Przesłałeś twoja odpowiedź na wywiad',
    thankyou: 'Dziękuję za odpowiedź! Jeśli masz jakieś pytania, skontaktuj się z nami :)',
  },
  interviewFailure: {
    title: 'Ups, link do wywiadu wydaje się być nieprawidłowy!',
    errorMessage: 'Zauważyliśmy, że łącze, do którego uzyskano dostęp, jest nieprawidłowe lub nie istnieje. Jeśli otrzymałeś ten link od pracodawcy, skontaktuj się bezpośrednio z pracodawcą, aby uzyskać nowy link.',
    contactUsBody: 'Nie można uzyskać dostępu do linku do wywiadu wideo &body=Cześć, nie mogę uzyskać dostępu do linku do wywiadu: {{- link }}. Mówi, że link do rozmowy kwalifikacyjnej wydaje się być zamknięty przez pracodawcę. Czy możesz mi pomóc spojrzeć?',
  },
  interviewSignUp: {
    name: 'Nazwa',
    namePlaceholder: 'Twoje imię',
    email: 'Email',
    emailPlaceholder: 'twój_email@przykład.com',
    school: 'Szkoła',
    phone: 'Telefon',
    linkedin: 'LinkedIn Profilować',
    resume: 'CV (pdf wyłącznie)',
    errorExpired: 'Ups, link do wywiadu wygasł!',
    errorMessage: 'Zauważyliśmy, że łącze, do którego uzyskano dostęp, nie jest już dostępne. Rozmowa kwalifikacyjna wygasła i nie została ponownie aktywowana przez pracodawcę. Jeśli uważasz, że praca jest nadal aktywna lub pracodawca nadal zatrudnia, skontaktuj się bezpośrednio z pracodawcą.',
    contactUsBody: 'Nie można uzyskać dostępu do linku do wywiadu wideo &body=Cześć, nie mogę uzyskać dostępu do linku do wywiadu: {{- link }}. Mówi się, że link do wywiadu nie jest już dostępny. Czy możesz mi pomóc spojrzeć?',
    toApplyFor: 'Aby ubiegać się o',
    coachingToApplyFor: 'Aby kontynuować ocenę, podaj szczegóły poniżej:',
    submitInfo: ' , podaj poniżej swoje informacje:',
    IP: {
      programOfStudy: 'Program of Study',
      school: 'School',
      formTitle: 'InsightPro Online Mock Video Interview Registration',
      instruction1: '1. Upon completing registration, you can choose either to start the video interview immediately or complete it later through the link provided via email.',
      instruction2: '2. The interview includes 8 behavioral questions and will last approximately 15 minutes.',
      instruction3: '3. Your Interview Assessment Report will be delivered to the registered email within 3 business days after the completion of the interview.',
      instruction4: '* Please note that incomplete or invalid interviews will not be reviewed and thus no assessment results will be available.',
      toApplyFor: 'To apply for',
      companyClient: ' {{companyName}}\'s client',
      at: 'at',
      howYouHear: 'How did you hear about this event?',
      indicateIndustry: '(Please indicate what industry and position(s) you are interested to pursue.)',
      yearOfGrad: 'Year of Graduation',
      careerInterests: 'Career Interests',
      submitInfo: ' , please submit your information below:',
      linkedin: 'Social Media - LinkedIn',
      wechat: 'Social Media - WeChat',
      friend: 'Friend',
      infoBooth: 'Information Booths',
      searchEngine: 'Search Engine',
    },
  },
  interviewSignUpSideScreen: {
    processHeading: '3-etapowy proces',
    fillApplication: 'Wypełnij wniosek',
    completeAssessment: 'Pełna ocena',
    videoResponse: 'Nagraj odpowiedź wideo',
  },
  multiAttempts: {
    title: 'O nie, link jest ważny tylko do jednorazowego dostępu!',
    errorMessage: 'Zauważyliśmy, że łącze, do którego uzyskano dostęp, nie jest już dostępne. Jest to link jednorazowy, jeśli nie udało Ci się wysłać odpowiedzi we wcześniejszej próbie. Skontaktuj się bezpośrednio z pracodawcą, aby uzyskać nowy link lub potwierdzić otrzymanie pierwszej odpowiedzi.',
    contactUsBody: 'Link do wywiadu jest ważny tylko do jednorazowego dostępu &body=Cześć, nie mogę uzyskać dostępu do linku do wywiadu: {{- link }}. Mówi, że link jest ważny tylko do jednorazowego dostępu. Czy możesz mi pomóc spojrzeć?',
  },
  preQuestionReview: {
    title: 'To jest nagranie pytania testowego',
    pleaseNote: 'Proszę zanotować:',
    description1: 'Chociaż ten film nie zostanie przesłany, tak będzie wyglądał Twój film ',
    description2: '. Upewnij się, że Twoja twarz jest widoczna przed kamerą, a Twój głos jest wyraźny. ',
    employer: 'pracodawca',
    warning: 'UWAGA: Po rozpoczęciu rozmowy kwalifikacyjnej nie będziesz mieć możliwości powrotu ani ponownego uruchomienia.',
    coachingWarning: 'UWAGA: Po rozpoczęciu testu nie będziesz mógł wrócić ani wznowić.',
    instruction1: 'Kiedy zaczynasz wywiad, masz tylko',
    coachingInstruction1: 'Rozpoczynając ocenę, masz tylko,',
    instruction2: 'przeczytać pytanie i przygotować się na odpowiedź. Powodzenia!',
    prepTime: '{{prepTime}} sekundy',
    troubleShootRedirection: 'Link do pomocy technicznej do pomocy i rozwiązywania problemów z kamerą/mikrofonem',
    clickLink: 'Kliknij tutaj',
  },
  interview: {
    testAgain: 'Przetestuj ponownie',
    startInterview: 'Rozpocznij Wywiad',
    recordResponse: 'Nagraj Odpowiedź',
    goodLuck: 'Powodzenia!',
    testQuestion: 'To jest pytanie testowe',
    interview: 'wartość',
    '10seconds': 'Masz 10 sekund na odpowiedź na to pytanie.',
    allowDevices: 'Musisz zezwolić przeglądarce na dostęp do kamery i mikrofonu.',
    done: 'Gotowe',
    preparation: 'Przygotowanie',
    remainingTime: 'Pozostały czas',
    back: 'Z powrotem',
    checkYourVideo: 'Sprawdź swój film',
    okayLetsProceed: 'OK, przejdźmy dalej',
    startPractice: 'Rozpocznij praktykę',
    importantNotes: 'Ważne notatki:',
    instruction1: 'Najpierw przetestuj za pomocą pytania praktycznego',
    instruction2: 'Nie będzie się to liczyć jako zgłoszenie',
    instruction3: 'Kliknij Rozpocznij test, aby rozpocząć',
    startTest: 'Rozpocznij test',
    alreadyApplied: 'Już stosowane!',
    alreadyAppliedToTheJob: 'Już aplikowałeś do tej pracy za pomocą',
    clickBelowToProceed: 'Kliknij poniżej, aby kontynuować.',
    continue: 'Kontynuować',
    blockerHeading: 'Aby przejść dalej, musisz włączyć uprawnienia do mikrofonu i wideo.',
    blockerPrimaryText: 'Aby włączyć uprawnienia do mikrofonu i wideo, wykonaj następujące czynności.',
    forChrome: 'Dla Chrome:',
    chromeBlockerStep1: '1) W prawym górnym rogu kliknij Więcej. Ustawienia.',
    chromeBlockerStep2: '2) Kliknij Prywatność i bezpieczeństwo Ustawienia witryny. Kamera lub mikrofon.',
    forFireFox: 'Dla Firefoksa:',
    firefoxBlockerStep1: '1) Kliknij przycisk menu i wybierz Ustawienia.',
    firefoxBlockerStep2: '2) Kliknij opcję Prywatność i bezpieczeństwo w menu po lewej stronie.',
    firefoxBlockerStep3: '3) Przewiń w dół do sekcji Uprawnienia.',
    firefoxBlockerStep4: '4) Kliknij przycisk Ustawienia dla opcji Mikrofon i wideo',
  },
  preQuestion: {
    qtitle: 'Opowiedz nam o swoim ulubionym kolorze i dlaczego?',
    title1: 'To jest ',
    title2: 'pytanie praktyczne ',
    title3: 'do przetestowania obrazu i dźwięku.',
    notSubmitted: 'Nie zostanie dodany do Twojego zgłoszenia.',
    toTest: 'wypróbować',
    instruction1: 'Gdy już poczujesz się komfortowo z nagrywaniem, kliknij',
    instruction2: 'na dole, aby kontynuować.',
    warning: 'UWAGA: Masz tylko 1 podejście do tego wywiadu. Użyj tego pytania, aby przetestować swój głos i wideo przed rozpoczęciem właściwego wywiadu.',
    '10seconds': 'Masz 10 sekund na odpowiedź na to pytanie. ',
    allowDevices: 'Musisz zezwolić przeglądarce na dostęp do kamery i mikrofonu.',
    numOfQuestions: 'Jest ich w sumie {{numberOfQuestions}} pytania w tym wywiadzie.',
    troubleShootRedirection: 'Link do pomocy technicznej do pomocy i rozwiązywania problemów z kamerą/mikrofonem',
    clickLink: 'Kliknij tutaj',
  },
  recording: {
    howManySeconds: ' Ty masz {{seconds}} sekund, aby odpowiedzieć na to pytanie.',
    recordingStatus: 'Nagranie',
    preparationStatus: 'Przygotowanie',
  },
  submitFailure: {
    ohNo: 'O nie!',
    description: 'Napotkaliśmy błąd podczas przesyłania Twojej odpowiedzi. Zwykle jest to spowodowane przerywanymi połączeniami sieciowymi, które powodują rezygnację z przesyłania. Przepraszamy za niedogodności.',
    instruction1: 'Niestety, nie udało nam się pomyślnie przechwycić zarejestrowanych odpowiedzi.',
    instruction2: 'Poświęć chwilę i ponownie zapisz swoje odpowiedzi na te pytania.',
    instruction3: 'Jeśli po ponownym przesłaniu nadal będziesz napotykać problemy, skontaktuj się z nami pod adresem ',
    instruction4: 'Możesz także ponownie nagrać swoje odpowiedzi na te pytania na innym urządzeniu, skanując poniższy kod qrcode.',
    recordAgain: 'Nagraj ponownie',
    networkStatus: 'Status sieci',
    connected: 'Połączony',
    notConnected: 'Nie połączony',
    check1: 'Sprawdź, czy ty',
    check2: 'mają dostęp do internetu tutaj.',
    reSubmit: 'Prześlij ponownie',
  },
  submittedScreen: {
    title: 'Twoja odpowiedź została przesłana!',
    thankyou: 'Dziękujemy za Twój cenny czas na rozmowę z nami. Mamy nadzieję, że podobało Ci się to doświadczenie!',
    redirect1: 'Zostaniesz przekierowany za {{time}} sekund...',
    orClick: 'lub kliknij ',
    here: 'tutaj',
    ifNotRedirected: 'jeśli nie zostaniesz przekierowany',
  },
  submittingScreen: {
    title: 'Przesyłamy Twoją odpowiedź, nie zamykaj przeglądarki...',
  },
  landing: {
    dear: 'Drogi {{name}},',
    hi: 'Cześć {{name}},',
    previous: 'Poprzedni',
    next: 'Kolejny',
    description: 'W ramach oceny rozmowy kwalifikacyjnej online musisz wypełnić rozmowę wideo dotyczącą roli',
    coachingDescription: 'W ramach oceny rozmowy kwalifikacyjnej online wymagane jest odbycie rozmowy wideo na temat roli',
    with: ' z ',
    at: 'w',
    companyClient: ' {{company}}\'s klient',
    quietSpot: 'Znajdź ciche miejsce z dużym oświetleniem i silną siecią Wi-Fi, aby dokonać oceny.',
    instructions: 'Instrukcje',
    instruction1: 'Ta ocena wideo składa się z',
    instruction1a: 'pytanie (pytania) i powinny cię zabrać',
    instruction1b: 'do wypełnienia, w zależności od liczby przydzielonych pytań.',
    instruction2: 'Musisz ukończyć ocenę w ciągu jednego posiedzenia.',
    instruction3: 'Po wyświetleniu pierwszego pytania uruchamia się automatyczny licznik czasu. Otrzymasz czas przygotowania wynoszący ',
    instruction3a: ', po którym następuje limit czasu nagrywania, który może być różny dla każdego pytania.',
    instruction4: 'Pamiętaj, aby śledzić licznik podczas przygotowywania lub rejestrowania odpowiedzi. Jeśli okaże się, że jesteś gotowy przed upływem terminu, możesz wybrać, czy chcesz zacząć rejestrować swoje odpowiedzi, czy przesłać je wcześniej.',
    coachingInstruction5: 'Uwaga: Nie odświeżaj, nie zamykaj ani nie zamykaj strony po rozpoczęciu testu, ponieważ nie będziesz już mógł uzyskać dostępu do linku testu.',
    instruction5: 'Uwaga: nie odświeżaj, nie zamykaj ani nie wychodź ze strony po rozpoczęciu wywiadu, ponieważ nie będziesz mieć dostępu do linku do wywiadu.',
    instruction6: 'Nie martw się, pod koniec całej oceny będziesz mógł przejrzeć swoje odpowiedzi wideo. Jeśli uważasz, że Twoje odpowiedzi są niezadowalające, możesz powtórzyć całą ocenę',
    instructionRedo: '* Jednak kolejność i charakter pytań mogą ulec zmianie, aby zapewnić, że podczas wywiadu wideo będziesz wyglądać tak naturalnie i spontanicznie.',
    envCheck: 'Kontrola sprzętu / środowiska',
    env1: 'Znajdź ciche miejsce z dużym oświetleniem i silną siecią Wi-Fi, aby dokonać oceny.',
    env2: 'Interviewer.AI nagrywanie działa tylko w Chrome i Firefox w tym momencie. Upewnij się, że używasz jednej z tych przeglądarek do przeprowadzenia oceny.',
    env3: 'Aby kontynuować rozmowę wideo, będziemy potrzebować dostępu do Twojej kamery wideo i mikrofonu.',
    coachingEnv3: 'Aby kontynuować rozmowę wideo, oszacowanie potrzebować dostępu do Twojej kamery wideo i mikrofonu.',
    env4: 'Zostanie wyświetlone pytanie testowe, w którym możesz przetestować jakość dźwięku i obrazu przed rozpoczęciem faktycznej oceny.',
    env5: 'Upewnij się, że dobrze się ustawisz w ramce ekranu, usiądź prosto i patrz prosto w aparat, kiedy prezentujesz odpowiedzi.',
    env6: 'Pod koniec całej oceny będziesz mieć możliwość przejrzenia swoich odpowiedzi wideo. Jeśli uważasz, że Twoje odpowiedzi są niezadowalające, możesz powtórzyć całą ocenę.',
    readyClick: 'Kliknij przycisk „Kontynuuj”, gdy będziesz gotowy. Wszystkiego najlepszego!',
    resumeOnly: 'CV (pdf tylko) *',
    error: 'Prześlij tylko plik pdf. Odrzucone pliki',
    env7: 'Nie przełączaj się między przeglądarkami podczas nagrywania.',
    env8: 'Utrzymuj kontakt wzrokowy i nie rozpraszaj się zbytnio podczas zapisywania odpowiedzi.',
    env9: 'Dźwięk jest odbierany podczas nagrywania. Upewnij się, że jesteś jedyną osobą mówiącą podczas nagrywania wywiadu.',
    instruction7: 'Prześlij odpowiednie dokumenty identyfikacyjne zgodnie z wymogami (dowód tożsamości itp.)',
  },
  resumeParser: {
    title: 'Resume Parser Demo',
    description: 'Upload a resume below to see how our system parse and evaluate the candidate\'s resume',
    dragResume: 'Drag and drop a resume here, or click to select file (pdf only)',
    parsedResults: 'Parsed results',
    experience: 'Experience',
    personalDetails: 'Personal Details',
    totalYoE: 'Total Years of Experience',
    company: 'Company',
    position: 'Position',
    duration: 'Duration',
    skills: 'Skills',
    education: 'Education',
    institute: 'Institute',
    degree: 'Degree',
    major: 'Major',
    score: 'Score',
    overall: 'Overall',
    betterFitNote: 'Note: Candidates with an Overall resume score of 55-100 are a better fit based on the assigned Job Description and Requirements.',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
  },
  countdown: {
    recordIn: 'Nagraj w',
  },
  prepTimer: {
    timer: 'Czas przygotowania',
  },
  candidateDetails: {
    back: 'Z powrotem',
    download: 'Ściągnij',
    questions: 'pytania',
    videoTranscript: 'Transkrypcja wideo',
    resumeScore: 'Wznów wynik',
    workmapScore: 'Workmap Wynik',
    aiVideoScore: 'AI Wideo Wynik',
    videoAssessment: 'Ocena wywiadu wideo',
    text: 'Tekst',
    voice: 'Głos',
    facialExpression: 'Wyraz twarzy',
    professionalism: 'Profesjonalizm',
    energyLevel: 'Poziomy energii',
    communication: 'Komunikacja',
    sociability: 'Towarzyskość',
    pace: 'Tempo',
    charity: 'Przejrzystość',
    sentiments: 'Sentyment',
    energy: 'Energia',
    positivity: 'Pozytywność',
    positiveEmotion: 'Pozytywne emocje',
    aboutThisAssessmentReport: 'O tym raporcie oceniającym',
    introduction: 'Wstęp',
    useOfReport: 'Korzystanie z raportu',
    confidentialityDisclaimer: 'Poufność i wyłączenie odpowiedzialności',
    workmapAssessment: 'Ocena mapy roboczej',
    actualScore: 'Rzeczywiste wyniki',
    resumeScoring: 'Wznów punktację',
    excellent: 'Doskonały',
    high: 'Wysoki',
    medium: 'Średni',
    low: 'Niski',
    glossaryIndex: 'Słowniczek/Indeks',
    definitionOfVideoInterviewParameter: 'Definicja parametrów wywiadu wideo',
    candidateSummary: 'Podsumowanie kandydata',
    notAvailable: 'Niedostępne',

  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND': 'Wypełnij rozmowę wideo, aby zobaczyć oceny.',
    '404_ASSESSMENT_DATA_NOT_READY': 'Twój wywiad wideo jest obecnie oceniany. Zapraszamy ponownie wkrótce!',
    '404_CANDIDATE_NOT_AVAILABLE': 'Nie zrobiłeś jeszcze żadnych wywiadów wideo. Wróć po przesłaniu odpowiedzi na rozmowę wideo.',
    default: 'Wystąpił błąd. Spróbuj ponownie później.',
  },
  dashboardPanel: {
    dashboard: 'Panel',
    hey: 'Siema',
    morningGreeting: 'dzień dobry',
    afternoonGreeting: 'Dobry wieczór',
    eveningGreeting: 'dobry wieczór',
    upgrade: 'Aktualizacja',
    support: 'Pomoc',
    signOut: 'Wyloguj się',
    collapse: 'Upadek',
    faqs: 'FAQs',
    company: 'Firma',
    role: 'rola',
    status: 'Status',
    action: 'akcja',
    date: 'fecha',
    recordNow: 'nagraj teraz',
    responded: 'Odpowiedziano',
    processing: 'Przetwarzanie',
    viewDetails: 'Pokaż szczegóły',
    personalInfo: 'Dane osobiste',
    introVideo: 'Film wprowadzający',
    score: 'Wynik',
    email: 'E-mail',
    phone: 'Telefon',
    summary: 'Streszczenie',
    topSkill: 'Najlepsze umiejętności',
    workmapScore: 'Workmap Score',
    aiVideoScore: 'AI Wynik wideo',
    introText1: 'Przygotuj się na ekscytujące informacje. Zacznij od nagrywania.',
    introText2: 'Twój film wprowadzający.',
    recordVideo: 'Nagrać wideo',
  },
  purchaseBanner: {
    heading: 'Uaktualnij, aby wyświetlić szczegółowy raport z wywiadu AI',
    subHeading: 'Bądź pewny siebie podczas następnej rundy rozmów kwalifikacyjnych.',
    launchAt: 'Uruchom ofertę na',
    only: 'Tylko',
    year: 'rok',
  },
  additionalFormField: {
    chooseFileBtn: 'Wybierz plik',
    pleaseChooseAFileText: 'Proszę wybrać plik',
  },
  companyDetails: {
    company: 'Firma',
    about: 'O',
    experience: 'Doświadczenie',
    apply: 'Stosować',
    description: 'Opis',
    viewJobDetailText: 'Wyświetl szczegóły oferty pracy',
    readMore: 'Czytaj więcej',
    readLess: 'Czytaj mniej',
  },
  processingVideo: {
    instruction1: 'Memproses video Anda...',
    instruction2: 'Harap tunggu',
    instruction3: 'Hanya butuh beberapa detik...',
    instruction4: 'Bersabarlah.',
    noAudioFound: 'Audio tidak ditemukan, harap rekam ulang dengan Audio yang jernih. Silakan periksa koneksi mikrofon Anda.',
  },
  audioError: {
    message: 'Przykro nam, że podczas transkrypcji Twojego filmu wystąpił błąd. Sprawdź dźwięk i spróbuj ponownie.',
  },
};
