export default {
  helloApplicant: 'Hallo Bewerber,',
  thankYou: 'Vielen Dank.',
  question: 'Frage',
  start: 'Start',
  submitting: 'Wird eingereicht',
  submit: 'Einreichen',
  sending: 'Wird gesendet',
  proceed: 'Weiter',
  close: 'Schließen',
  poweredBy: 'Powered by',
  contactUs: 'Kontakt',
  showMore: 'Mehr anzeigen',
  showLess: 'Weniger anzeigen',
  applying: 'Wird übernommen',
  apply: 'Übernehmen',
  click: 'Klicken',
  done: 'Fertig',
  uploading: 'Wird hochgeladen',
  cantFindPage: 'Wir können die von Ihnen gesuchte Seite nicht finden.',
  usefulLinks: 'Hier sind einige Links, die nützlich sein können.',
  menuHome: 'Startseite',
  menuAboutUs: 'Über uns',
  menuTnC: 'Allgemeine Geschäftsbedingungen',
  menuPrivacy: 'Datenschutz',
  tnc1: 'Mit Ihrer Bewerbung erklären Sie sich einverstanden mit den ',
  tnc2: 'Interviewer.AI-Nutzungsbedingungen für Bewerber',
  amInterested: 'Ich bin interessiert',
  second: 'Sekunde',
  second_plural: 'Sekunden',
  secondWithCount: '{{count}} Sekunde',
  secondWithCount_plural: '{{count}} Sekunden',
  minute: 'minute',
  minute_plural: 'minuten',
  minuteWithCount: '{{count}} minute',
  minuteWithCount_plural: '{{count}} minuten',
  workmapScreen: {
    greeting: 'Liebe/r {{Name}},',
    unansweredQuestions: 'Ihre unbeantworteten Fragen sind:',
    description: 'Sie sind eingeladen, an einer Workmap-Kompetenzbewertung für die Funktion {{jobName}} bei {{companyName}} teilzunehmen.',
    instruction: 'Anweisungen',
    instruction1: 'Bitte geben Sie Ihre Antwort zu jeder Aussage darauf basierend, wie Sie sich die meiste Zeit bei der Arbeit gefühlt haben.',
    instruction2: 'Wählen Sie bei der Beantwortung der Aussagen die Option aus, die Ihrer allgemeinen Denkweise oder Ihrem allgemeinen Gefühlszustand entspricht.',
    instruction3: 'Bei dieser Bewertung gibt es keine richtigen oder falschen Antworten, verlieren Sie daher nicht zu viel Zeit mit einer einzigen Antwort.',
    instruction4: 'Seien Sie spontan und versuchen Sie nicht, darauf basierend zu antworten, was wir Ihrer Meinung nach hören wollen.',
    instruction5: 'Geben Sie ehrliche Antworten. Diese wirken sich auf die Genauigkeit Ihrer Bewertung aus.',
    instruction6: 'Diese Bewertung sollte etwa 5 Minuten dauern. Klicken Sie auf die Schaltfläche „Start“, wenn Sie bereit sind. Viel Glück!',
    goBack: 'Zurückgehen',
    skillsAssessment: 'Kompetenzbewertung',
    of: 'von',
    answers: 'Antworten',
    questions: 'Fragen',
    submit: 'Einreichen',
    start: 'Start',
    instruction7: 'Es gibt noch Fragen, auf die Sie nicht geantwortet haben. Bitte beantworten Sie vor dem Einreichen alle Fragen.',
    stronglyDisagree: 'Stimme überhaupt nicht zu',
    disagree: 'Stimme nicht zu',
    somewhatDisagree: 'Stimme eher nicht zu',
    neitherAgreeNorDisagree: 'Stimme weder zu noch nicht zu',
    somewhatAgree: 'Stimme eher zu',
    agree: 'Stimme zu',
    stronglyAgree: 'Stimme voll und ganz zu',
  },
  scale: {
    'Not at all': 'Überhaupt nicht',
    Rarely: 'Selten',
    Sometimes: 'Manchmal',
    Often: 'Oft',
    'Very Often': 'Sehr oft',
    'Strongly Disagree': 'Stimme überhaupt nicht zu',
    'Somewhat Disagree': 'Stimme eher nicht zu',
    Disagree: 'Stimme nicht zu',
    'Neither Agree nor Disagree': 'Stimme weder zu noch nicht zu',
    Neutral: 'Neutral',
    Agree: 'Stimme zu',
    'Somewhat Agree': 'Stimme eher zu',
    'Strongly Agree': 'Stimme voll und ganz zu',
  },
  applySendSuccessful: {
    thankyou: 'Vielen Dank für Ihre Bewerbung!',
    instruction: 'Wir haben Ihnen eine E-Mail mit dem Link zur Interviewseite geschickt. Wir empfehlen Ihnen, die Aufzeichnung innerhalb der nächsten 48 Stunden vorzunehmen, um Ihre Chancen auf eine Einladung zur Teilnahme an der nächsten Runde zu erhöhen. Viel Glück!',
  },
  applySuccessful: {
    congrats: 'Hallo, {{name}}!',
    submitted: 'Vielen Dank für die bereitgestellten Informationen.',
    whatsNext: 'Was gibt es Neues?',
    instruction: 'Der nächste Schritt im Bewerbungsverfahren ist die Aufzeichnung von Videoantworten auf Interviewfragen.',
    coachingInstruction: 'Im Rahmen der Online-Bewertung müssen Sie Videoantworten auf die Bewertungsfragen aufzeichnen.',
    instruction1: '1. Wählen Sie einen ruhigen Ort und sorgen Sie für eine ausreichende Beleuchtung.',
    instruction2: '2. Stellen Sie sicher, dass die Netzwerkverbindung stabil ist.',
    instruction3: '3. Seien Sie selbstbsicher und laut genug, damit wir Sie deutlich hören können.',
    instruction4: '4. Die Videos können jederzeit vor der Ablauffrist gemacht werden.',
    instruction5: 'Wir freuen uns auf Ihre Videoantworten und wünschen Ihnen viel Glück!',
    giveBestShot: 'Das ist Ihre Chance, Ihre Persönlichkeit, Ihre Leidenschaft und Ihre Energie zu zeigen, also geben Sie Ihr Bestes!',
    helpfulTips: 'Hilfreiche Tipps:',
    doWithin48: 'Erledigen Sie es innerhalb von 48 Stunden – schicken Sie mir den Link.',
    wsgClientInstruction1:
      '5. Bitte beachten Sie, dass WSG alle aufgezeichneten Videos vor der Übermittlung an die Arbeitgeber überprüft. Videos, die unangemessenes oder unprofessionelles Verhalten enthalten oder zeigen, wie z. B. die Verwendung von Schimpfwörtern oder eines unangemessenen Hintergrunds, werden nicht an die Arbeitgeber zur Bearbeitung der Bewerbungen übermittelt. Die Bewerber werden in einer Mitteilung gebeten, ihr aufgezeichnetes Video zu überarbeiten und erneut abzuschicken.',
    doNow: 'Jetzt starten',
    infoProvide: 'Vielen Dank für die bereitgestellten Informationen',
    opportunityText: 'Dies ist Ihre Gelegenheit, Ihre Persönlichkeit, Leidenschaft und Energie zu zeigen, also geben Sie Ihr Bestes!',
    quietSpot: 'Ruhiger Ort und dafür sorgen',
    strongNetwork: 'Starkes Netzwerk',
    confidentLoud: 'Selbstbewusst und laut genug',
    videoCompleted: 'Videos können vervollständigt werden',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'Vielen Dank, {{name}}!',
    submitted: 'Ihre Bewerbung wurde eingereicht.',
    instruction: 'Unser Team wird Ihre Bewerbung prüfen und sich in Kürze mit Ihnen in Verbindung setzen, falls Sie für die nächste Runde in die engere Wahl kommen. Bitte beachten Sie, dass nur Bewerber, die in die engere Wahl kommen, benachrichtigt werden.',
    goodLuck: 'Wir wünschen Ihnen viel Glück!',
  },
  candidateProfile: {
    intro: 'Stellen Sie sich vor',
    instruction: ' Bitte nehmen Sie sich vor dem Videointerview einen Moment Zeit, um sich vorzustellen.',
    placeholder: 'Erzählen Sie uns etwas über sich',
  },
  browser: {
    worksBest: 'Interviewer.AI funktioniert derzeit am besten in Chrome und Firefox.',
    bestExperience: 'Wir arbeiten daran, sicherzustellen, dass jeder ein bestmögliches Erlebnis beim Interviewen hat!',
    meantime: 'In der Zwischenzeit installieren Sie bitte die neueste Version von Google Chrome oder Mozilla Firefox und greifen Sie über denselben Interview-Link auf Ihr Interview zu:',
    downloadChrome: 'Chrome herunterladen',
    downloadFirefox: 'Firefox herunterladen',
    lookForward: 'Wir freuen uns auf Ihre Antworten!',
    bestRegards: 'Mit freundlichen Grüßen,',
    interviewerTeam: 'Interviewer.AI-Team',
    scanQrCode: 'Scannen Sie den QR-Code, um auf dem Handy aufzuzeichnen',
  },
  digitalProfile: {
    interviews: 'Interviews',
    interviewName: 'Name des Interviews',
    status: 'Status',
    dateResponded: 'Datum der Beantwortung',
    summary: 'Übersicht',
    topSkills: 'Top-Kompetenzen',
    viewResume: 'Lebenslauf ansehen',
    visitLinkedin: 'Linkedin besuchen',
    myWorkmapScore: 'Meine Workmap-Punktzahl',
  },
  organisationIntroVideoScreen: {
    continue: 'weitermachen',
    note: '(Hinweis: Die Schaltfläche „Weiter“ wird aktiviert, nachdem Sie das vollständige Video angesehen haben)',
  },

  endedScreen: {
    recordAgain: 'Erneut aufzeichnen',
    recordAgainQuestion: 'Sind Sie sicher, dass Sie die Aufzeichnung wiederholen möchten?',
    recordAgainDescription: 'Bitte beachten Sie, dass Sie in diesem Fall das gesamte Interview ab Frage 1 erneut durchführen müssen. Klicken Sie auf die Schaltfläche „Erneut aufzeichnen“, um neu zu beginnen, andernfalls schließen Sie dieses Fenster, um Ihre Antworten einzureichen.',
    thankyou: 'Vielen Dank, {{name}}! Sie haben das Interview abgeschlossen.',
    coachingThankyou: 'Vielen Dank, {{name}}! Sie haben die Bewertung abgeschlossen.',
    instruction1: 'Sie können Ihre Antworten noch einmal überprüfen, bevor',
    instruction2: ' Sie sie über die Schaltfläche am Ende der Seite einreichen.',
    getFeedback: 'Wir freuen uns über Ihr Feedback unten. Dieses hilft uns, unser Produkt zu verbessern! Welche Erfahrung haben Sie beim Aufzeichnen gemacht?',
    feedback: 'Feedback',
    placeholder: 'Wir schätzen Ihr Feedback sehr!',
    rateExp: 'Bewerten Sie Ihre Interviewerfahrung',
    Hi: 'Hallo!',
    checkVideo: 'Lassen Sie uns Ihr Video überprüfen.',
    question: 'Fragen',
    submit: 'Einreichen',
  },
  thankyouEndScreen: {
    radioText1: 'Kein Interesse an dem Job',
    radioText2: 'Kein Interesse an Videointerview',
    radioText3: 'Werde es später machen',
    radioText4: 'Einreichen nicht möglich',
    radioText5: 'Andere',
    pleaseChooseReason: 'Bitte wählen Sie einen Grund',
    stateYourReason: 'Bitte geben Sie Ihren Grund an',
    notFinished: 'Sie haben das Interview noch nicht beendet.',
    notSubmittedVideoResp: 'Ihre Videoantwort wurde nicht übermittelt.',
    noSubmittion: 'Keine Einreichung!',
    shareYourFeedback: 'Bitte teilen Sie uns Ihr Feedback mit.',
    tellUsQuittingInterview: 'Teilen Sie uns mit, wenn Sie das Vorstellungsgespräch abbrechen oder vor Herausforderungen stehen.',
    ratingStarsMandatory: 'Bewertungssterne sind Pflicht',
    pleaseDontLeaveField: 'Bitte lassen Sie dieses Feld nicht leer',
    congratulations: 'Herzlichen Glückwunsch',
    responseSent: 'Ihre Videoantworten wurden erfolgreich gesendet.',
    instruction1: 'Warten Sie, bis Sie eine Nachricht über die erfolgreiche Übermittlung erhalten, da Ihre Bewerbung möglicherweise noch in Bearbeitung ist',
    instruction2: 'Wir tun unser Bestes, um Ihre Antwort innerhalb weniger Minuten zu bewerten. Es kann jedoch zwischen 30 Minuten und 2 Stunden dauern. Bitte räumen Sie uns etwas Zeit für die Bearbeitung ein.',
    instruction3: 'Sie erhalten eine E-Mail, sobald Ihre Antwort bewertet wurde. Schauen Sie in Ihrem Spam-Ordner nach, falls Sie innerhalb von 2 Stunden keine E-Mail erhalten.',
    instruction4: 'Für weitere Fragen können Sie uns unter support@interviewer.ai erreichen. Viel Spaß beim Interview!',
    finishedInterview: 'Sie haben das Interview abgeschlossen.',
    shareExperience: 'Teilen Sie Ihre Erfahrung beim Aufzeichnen.',
    submit: 'Einreichen',
    thankyou: 'Vielen Dank',
    feedbackText: 'Wir freuen uns über Ihr Feedback unten. Dieses hilft uns, unser Produkt zu verbessern! Welche Erfahrung haben Sie beim Aufzeichnen gemacht?',
    feedback: 'Feedback',
  },
  interviewCompleted: {
    submitted: 'Sie haben eine Antwort auf das Interview eingereicht.',
    thankyou: 'Vielen Dank für Ihre Antwort! Wenn Sie noch Fragen haben, können Sie sich gerne mit uns in Verbindung setzen :)',
  },
  interviewFailure: {
    errorMessage: 'Wir haben festgestellt, dass der Link, den Sie aufgerufen haben, ungültig ist oder nicht existiert. Falls Sie diesen Link von einem Arbeitgeber erhalten haben, wenden Sie sich bitte direkt an den Arbeitgeber, um einen neuen Link zu erhalten.',
    title: 'Ups, der Link zum Interview scheint ungültig zu sein!',
    contactUsBody: 'Konnte den Link zum Videointerview nicht aufrufen&body=Hallo, ich konnte den Link zum Interview nicht aufrufen: {{- link }}. Es heißt, der Link zum Interview sei vom Arbeitgeber entfernt worden. Könnten Sie dies bitte für mich überprüfen?',
  },
  interviewSignUp: {
    name: 'Name',
    apply: 'Jetzt bewerben',
    submit: 'Einreichen',
    experience: 'Erfahrung',
    seniorityLevel: 'Betriebszugehörigkeit',
    employmentType: 'Art der Beschäftigung',
    roleOverview: 'Übersicht über die Funktionen',
    companyName: 'Unternehmen',
    namePlaceholder: 'Ihr Name',
    applicationForm: 'Bewerbungsformular',
    required: 'Erforderlich',
    email: 'E-Mail-Adresse',
    emailPlaceholder: 'Ihre_E-Mail-Adresse@Beispiel.com',
    school: 'Fachbereich',
    phone: 'Telefon',
    linkedin: 'LinkedIn-Profil',
    facebook: 'Facebook-Profil',
    instagram: 'Instagram-Profil',
    resume: 'Lebenslauf (nur pdf, doc und docx)',
    errorExpired: 'Ups, der Link zum Interveiw scheint abgelaufen zu sein!',
    errorMessage: 'Wir haben festgestellt, dass der Link, den Sie aufgerufen haben, nicht mehr verfügbar ist. Das Interview ist abgelaufen und wurde vom Arbeitgeber nicht reaktiviert. Falls Sie der Meinung sind, dass die Stelle noch aktiv ist oder der Arbeitgeber noch Mitarbeiter einstellt, wenden Sie sich bitte direkt an den Arbeitgeber.',
    contactUsBody: 'Konnte den Link zum Videointerview nicht aufrufen&body=Hallo, ich konnte den Link zum Interview nicht aufrufen: {{- link }}. Es heißt, der Link zum Interview sei nicht mehr aufrufbar. Könnten Sie dies bitte für mich überprüfen?',
    toApplyFor: 'Für die Bewerbung um',
    coachingToApplyFor: 'Um mit Ihrer Bewertung fortzufahren, übermitteln Sie bitte Ihre Daten unten:',
    submitInfo: ' , übermitteln Sie bitte Ihre Informationen unten:',
    selectFile: 'Datei auswählen',
    supportPDF: 'Unterstützt nur PDF, doc und docx',
    dragPDF: 'Ziehen Sie Ihre Datei hierher oder klicken Sie darauf',
    browse: 'Durchsuchen',
    your: 'Ihr',
    IP: {
      programOfStudy: 'Studiengang',
      school: 'Fachbereich',
      formTitle: 'InsightPro-Registrierung für Online-Probe-Videointerview',
      instruction1: '1. Nach der Registrierung haben Sie die Möglichkeit, das Videointerview sofort zu starten oder über den per E-Mail bereitgestellten Link später durchzuführen.',
      instruction2: '2. Das Interview umfasst 8 verhaltensbezogene Fragen und dauert etwa 15 Minuten.',
      instruction3: '3. Ihr Interviewbewertungsbericht wird Ihnen innerhalb von 3 Werktagen nach Abschluss des Interviews an die registrierte E-Mail-Adresse zugestellt.',
      instruction4: '* Bitte beachten Sie, dass unvollständige oder ungültige Interviews nicht geprüft werden und daher keine Bewertungsergebnisse verfügbar sein werden.',
      toApplyFor: 'Für die Bewerbung um',
      at: 'bei',
      howYouHear: 'Wie haben Sie von dieser Veranstaltung erfahren?',
      indicateIndustry: '(Bitte geben Sie an, welche Branche und welche Position(en) Sie anstreben.)',
      yearOfGrad: 'Abschlussjahr',
      careerInterests: 'Berufliche Interessen',
      submitInfo: ' , übermitteln Sie bitte Ihre Informationen unten:',
      linkedin: 'Soziale Medien – LinkedIn',
      wechat: 'Soziale Medien – WeChat',
      friend: 'Freund',
      infoBooth: 'Informationsstände',
      searchEngine: 'Suchmaschine',
    },
  },
  interviewSignUpSideScreen: {
    processHeadingWithWorkmapEnabled: '3-Stufen-Prozess',
    processHeadingWithoutWorkmapEnabled: '2-Stufen-Prozess',
    fillApplication: 'Bewerbungsformular ausfüllen',
    completeAssessment: 'Bewertung durchführen',
    videoResponse: 'Videoantwort aufzeichnen',
  },
  multiAttempts: {
    title: 'Oh nein, der Link ist nur für einen einmaligen Zugriff gültig!',
    errorMessage: 'Wir haben festgestellt, dass der Link, den Sie aufgerufen haben, nicht mehr verfügbar ist. Es handelt sich um einen einmalig gültigen Zugriffslink, daher konnten Sie ggf. Ihre Antwort beim ersten Versuch nicht einreichen. Bitte setzen Sie sich direkt mit dem Arbeitgeber in Verbindung, um einen neuen Link zu erhalten oder um den Erhalt Ihrer ersten Antwort bestätigen zu lassen.',
    contactUsBody: 'Der Link zum Interview ist nur für einen einmaligen Zugriff gültig&body=Hallo, ich konnte den Link zum Interview nicht aufrufen: {{- link }}. Es heißt, der Link sei nur für einen einmaligen Zugriff gültig. Könnten Sie dies bitte für mich überprüfen?',
  },
  preQuestionReview: {
    title: 'Dies ist eine Aufzeichnung der Testfrage',
    pleaseNote: 'Bitte beachten Sie:',
    description1: 'Dieses Video wird zwar nicht eingereicht, aber so erscheint Ihr Video für',
    description2: '. Bitte achten Sie darauf, dass Ihr Gesicht vor der Kamera zu sehen ist und Ihre Stimme klar und deutlich ist.',
    employer: 'Arbeitgeber',
    warning: 'HINWEIS: Sobald Sie das Interview begonnen haben, können Sie nicht mehr zurückgehen oder von vorne beginnen.',
    coachingWarning: 'HINWEIS: Sobald Sie die Bewertung begonnen haben, können Sie nicht mehr zurückgehen oder von vorne beginnen.',
    instruction1: 'Wenn Sie das Interview beginnen, müssen Sie nur',
    coachingInstruction1: 'Wenn Sie die Bewertung beginnen, müssen Sie nur',
    instruction2: 'die Frage lesen und sich auf Ihre Antwort vorbereiten. Viel Glück!',
    prepTime: '{{prepTime}} Sekunden ',
    troubleShootRedirection: 'Support-Link zur Unterstützung bei und Behebung von Problemen mit der Kamera und/oder dem Mikrofon ',
    clickLink: 'klicken Sie hier',
  },
  interview: {
    testAgain: 'Erneut testen',
    startInterview: 'Interview starten',
    recordResponse: 'Antwort aufzeichnen',
    testQuestion: 'Dies ist eine Testfrage',
    goodLuck: 'Viel Glück!',
    interview: 'Wert',
    '10seconds': ' Sie haben 10 Sekunden Zeit, um diese Frage zu beantworten.',
    allowDevices: 'Sie müssen dem Browser den Zugriff auf die Kamera und das Mikrofon erlauben.',
    done: 'Fertig',
    preparation: 'Vorbereitung',
    remainingTime: 'Verbleibende Zeit',
    back: 'Zurück',
    checkYourVideo: 'Überprüfen Sie Ihr Video',
    okayLetsProceed: 'Okay, weiter geht’s',
    startPractice: 'Übung beginnen',
    importantNotes: 'Wichtige Hinweise:',
    instruction1: 'Testen Sie es zunächst mit einer Übungsfrage.',
    instruction2: 'Dies wird nicht als Einreichung gewertet.',
    instruction3: 'Klicken Sie auf „Test starten“, um zu beginnen.',
    startTest: 'Test starten',
    alreadyApplied: 'Bereits beworben!',
    alreadyAppliedToTheJob: 'Sie haben sich bereits um diese Stelle beworben, und zwar mit',
    clickBelowToProceed: 'Klicken Sie unten, um fortzufahren.',
    continue: 'Weiter',
    blockerHeading: 'Sie müssen Ihre Mikrofon- und Videoberechtigungen aktivieren, um fortzufahren.',
    blockerPrimaryText: 'Um die Mikrofon- und Videoberechtigungen zu aktivieren, befolgen Sie bitte die folgenden Schritte.',
    forChrome: 'Für Chrom:',
    chromeBlockerStep1: '1) Klicken Sie oben rechts auf Mehr. Einstellungen.',
    chromeBlockerStep2: '2) Klicken Sie auf Datenschutz und Sicherheit Site-Einstellungen. Kamera oder Mikrofon.',
    forFireFox: 'Für Firefox:',
    firefoxBlockerStep1: '1) Klicken Sie auf die Menüschaltfläche und wählen Sie Einstellungen.',
    firefoxBlockerStep2: '2) Klicken Sie im linken Menü auf Datenschutz & Sicherheit.',
    firefoxBlockerStep3: '3) Scrollen Sie nach unten zum Abschnitt Berechtigungen.',
    firefoxBlockerStep4: '4) Klicken Sie auf die Schaltfläche Einstellungen für die Option Mikrofon und Video',
  },
  preQuestion: {
    qtitle: 'Verraten Sie uns Ihre Lieblingsfarbe und warum?',
    title1: 'Dies ist eine',
    title2: ' Übungsfrage, ',
    title3: 'um Ihre Video- und Tonqualität zu testen.',
    notSubmitted: 'Sie wird nicht zu Ihrer Einreichung hinzugefügt.',
    toTest: 'zum Testen',
    instruction1: ' Sobald Sie sich mit den Schritten für die Aufzeichnung vertraut gemacht haben, klicken Sie auf',
    instruction2: 'am unteren Rand, um fortzufahren.',
    warning: 'HINWEIS: Sie haben nur 1 Versuch für dieses Interview. Bitte verwenden Sie diese Frage, um Ihre Stimme und Ihre Videoqualität zu testen, bevor Sie mit dem eigentlichen Interview beginnen.',
    coachingWarning: 'HINWEIS: Sie haben nur 1 Versuch für diese Bewertung. Bitte verwenden Sie diese Übungsfrage, um Ihre Stimme und Ihre Videoqualität zu testen, bevor Sie mit der eigentlichen Bewertung beginnen.',
    '10seconds': ' Sie haben 10 Sekunden Zeit, um diese Frage zu beantworten.',
    allowDevices: 'Sie müssen dem Browser den Zugriff auf die Kamera und das Mikrofon erlauben.',
    numOfQuestions: 'Es gibt insgesamt {{numberOfQuestions}} Fragen in diesem Interview.',
    troubleShootRedirection: 'Support-Link zur Unterstützung bei und Behebung von Problemen mit der Kamera und/oder dem Mikrofon ',
    clickLink: 'klicken Sie hier',
  },
  recording: {
    howManySeconds: 'Sie haben {{seconds}} Sekunden Zeit, um diese Frage zu beantworten.',
    recordingStatus: 'Aufzeichnung',
    preparationStatus: 'Vorbereitung',
  },
  submitFailure: {
    ohNo: 'Oh nein!',
    description: 'Bei der Einreichung Ihrer Antwort ist ein Fehler aufgetreten. Dies ist in der Regel auf eine unterbrochene Netzwerkverbindung zurückzuführen, die dazu führt, dass die Einreichung abgebrochen wird. Wir entschuldigen uns für die Unannehmlichkeiten.',
    instruction1: 'Leider konnten wir Ihre aufgezeichneten Antworten nicht erfolgreich erfassen.',
    instruction2: 'Bitte nehmen Sie sich einen Moment Zeit, um Ihre Antworten auf diese Fragen noch einmal aufzuzeichnen.',
    instruction3: 'Wenn Sie nach der erneuten Einreichung weiterhin auf Probleme stoßen, können Sie sich gerne an uns wenden unter ',
    instruction4: 'Sie können Ihre Antworten auf diese Fragen auch auf einem anderen Gerät erneut aufzeichnen, indem Sie den folgenden QR-Code scannen.',
    recordAgain: 'Nochmals aufnehmen',
    networkStatus: 'Netzwerkstatus',
    connected: ' Verbunden',
    notConnected: ' Nicht verbunden',
    check1: 'Überprüfen Sie hier, ob Sie ',
    check2: 'Zugang zum Internet haben.',
    reSubmit: 'Erneut einreichen',
  },
  submittedScreen: {
    title: 'Ihre Antwort wurde eingereicht!',
    thankyou: 'Vielen Dank für Ihre wertvolle Zeit, die Sie in dieses Interview für uns investiert haben. Wir hoffen, es hat Ihnen gefallen!',
    redirect1: 'Sie werden jetzt in {{time}} Sekunden weitergeleitet ...',
    orClick: 'Oder klicken Sie ',
    here: 'hier',
    ifNotRedirected: ' wenn Sie nicht weitergeleitet werden',
    redirectToPortal: 'Um sich im Bewerberportal anzumelden oder zu registrieren.',
    clickHere: 'Hier klicken',
  },
  submittingScreen: {
    title: 'Wir übermitteln jetzt Ihre Antworten, bitte schließen Sie Ihren Browser nicht …',
  },
  landing: {
    dear: 'Liebe/r {{name}},',
    hi: 'Hallo {{name}},',
    previous: 'Vorherige',
    next: 'Nächste',
    description: 'Im Rahmen des Online-Interviews müssen Sie ein Videointerview durchführen für die Funktion des',
    coachingDescription: 'Im Rahmen der Online-Bewertung müssen Sie ein Videointerview durchführen für die Funktion des',
    with: 'mit ',
    at: 'bei',
    quietSpot: 'Suchen Sie sich einen ruhigen Ort mit ausreichender Beleuchtung und stabiler WLAN-Verbindung, um Ihre Bewertung zu absolvieren.',
    instructions: 'Anweisungen',
    instruction1: 'Diese Videobewertung besteht aus',
    instruction1a: 'Frage(n) und sollte',
    instruction1b: 'dauern, je nachdem, wie viele Fragen Ihnen zugewiesen wurden.',
    instruction2: 'Sie müssen die Bewertung in einer einzigen Sitzung absolvieren.',
    instruction3: 'Sobald Ihnen die erste Frage gestellt wird, startet ein automatischer Timer. Sie erhalten eine Vorbereitungszeit von ',
    instruction3a: ', gefolgt von einem Zeitlimit für die Aufzeichnung, das von Frage zu Frage variieren kann.',
    instruction4: 'Denken Sie daran, den Timer im Auge zu behalten, während Sie Ihre Antwort vorbereiten oder aufzeichnen. Sollten Sie vor Ablauf des Zeitlimits fertig sein, können Sie entweder mit der Aufzeichnung Ihrer Antworten beginnen oder sie bereits vorher einreichen.',
    instruction5: 'Bitte beachten Sie: Aktualisieren, schließen oder verlassen Sie die Seite nicht, sobald das Interview begonnen hat, da Sie sonst nicht mehr auf den Link zum Interview zugreifen können.',
    coachingInstruction5: 'Bitte beachten Sie: Aktualisieren, schließen oder verlassen Sie die Seite nicht mehr, sobald die Bewertung begonnen hat, da Sie sonst nicht mehr auf den Link zur Bewertung zugreifen können.',
    instruction6: 'Keine Sorge, Sie können Ihre Videoantworten am Ende der gesamten Bewertung überprüfen. Wenn Sie das Gefühl haben, dass Ihre Antworten nicht zufriedenstellend sind, haben Sie die Möglichkeit, die gesamte Bewertung zu wiederholen',
    instructionRedo: '* Die Reihenfolge und die Art der Fragen können jedoch geändert werden, um sicherzustellen, dass Sie während des Videointerviews natürlich und spontan wirken.',
    envCheck: 'Überprüfung der Ausrüstung/Umgebung',
    env1: 'Suchen Sie sich einen ruhigen Ort mit ausreichender Beleuchtung und stabiler WLAN-Verbindung, um Ihre Bewertung zu absolvieren.',
    env2: 'Die Aufzeichnung des Interviews funktioniert derzeit nur mit Chrome und Firefox. Vergewissern Sie sich, dass Sie einen dieser beiden Browser verwenden, um Ihre Bewertung zu absolvieren.',
    env3: 'Damit Sie mit dem Videointerview fortfahren können, benötigen wir Zugang zu Ihrer Videokamera und Ihrem Mikrofon.',
    coachingEnv3: 'Damit Sie mit der Videobewertung fortfahren können, benötigen wir Zugang zu Ihrer Videokamera und Ihrem Mikrofon.',
    env4: 'Ihnen wird eine Übungsfrage gezeigt, mit der Sie Ihre Audio- und Videoqualität testen können, bevor Sie mit der eigentlichen Bewertung beginnen.',
    env5: 'Achten Sie darauf, dass Sie sich gut innerhalb des Rahmens Ihres Bildschirms positionieren, aufrecht sitzen und direkt in die Kamera schauen, wenn Sie Ihre Antworten geben.',
    env6: 'Sie können Ihre Videoantworten am Ende der gesamten Bewertung überprüfen. Wenn Sie das Gefühl haben, dass Ihre Antworten nicht zufriedenstellend sind, haben Sie die Möglichkeit, die gesamte Bewertung zu wiederholen.',
    readyClick: 'Klicken Sie auf die Schaltfläche „Fortfahren“, wenn Sie bereit sind. Viel Glück!',
    resumeOnly: 'Lebenslauf (nur pdf, doc und docx) *',
    error: 'Bitte laden Sie nur eine PDF-Datei hoch. Abgelehnte Dateien',
    env7: 'Wechseln Sie während der Aufnahme nicht zwischen Ihren Browsern.',
    env8: 'Halten Sie Augenkontakt und lenken Sie sich beim Aufzeichnen Ihrer Antworten nicht zu sehr ab.',
    env9: 'Der Ton wird während der Aufnahme aufgenommen. Stellen Sie sicher, dass Sie während der Aufzeichnung des Interviews die einzige Person sind, die spricht.',
    instruction7: 'Bitte laden Sie die richtigen Ausweisdokumente wie vorgeschrieben hoch (Regierungsausweis usw.)',
  },
  resumeParser: {
    title: 'Lebenslaufanalyse-Demo',
    description: 'Laden Sie unten einen Lebenslauf hoch, um zu sehen, wie unser System den Lebenslauf des Bewerbers analysiert und bewertet.',
    dragResume: 'Ziehen Sie per Drag & Drop einen Lebenslauf hierher oder klicken Sie, um eine Datei auszuwählen (nur PDf).',
    parsedResults: 'Analyseergebnisse',
    experience: 'Erfahrung',
    personalDetails: 'Persönliche Daten',
    totalYoE: 'Gesamtanzahl der Jahre an Erfahrung',
    company: 'Unternehmen',
    position: 'Position',
    duration: 'Dauer',
    skills: 'Kompetenzen',
    education: 'Ausbildung',
    institute: 'Institut',
    degree: 'Abschluss',
    major: 'Studiengang',
    score: 'Punktzahl',
    overall: 'Insgesamt',
    betterFitNote: 'Hinweis: Bewerber mit einer Lebenslaufpunktzahl von ingesamt 55–100 passen besser auf die zugewiesene Stellenbeschreibung und die Stellenanforderungen.',
    name: 'Name',
    email: 'E-Mail-Adresse',
    phone: 'Telefon',
  },
  countdown: {
    recordIn: 'Aufzeichnen in',
  },
  prepTimer: {
    timer: 'Vorbereitungszeit',
  },
  candidateDetails: {
    back: 'Zurück',
    download: 'Herunterladen',
    questions: 'Fragen',
    videoTranscript: 'Videotranskript',
    resumeScore: 'Lebenslaufpunktzahl',
    workmapScore: 'Workmap-Punktzahl',
    aiVideoScore: 'KI-Videopunktzahl',
    videoAssessment: 'Videointerviewbewertung',
    text: 'Text',
    voice: 'Stimme',
    facialExpression: 'Gesichtsausdruck',
    professionalism: 'Professionalität',
    energyLevel: 'Energieniveau',
    communication: 'Kommunikation',
    sociability: 'Kontaktfreudigkeit',
    pace: 'Tempo',
    charity: 'Klarheit',
    sentiments: 'Stimmung',
    energy: 'Energie',
    positivity: 'Positivität',
    positiveEmotion: 'Positive Emotion',
    aboutThisAssessmentReport: 'Über diesen Bewertungsbericht',
    introduction: 'Einführung',
    useOfReport: 'Verwendung des Berichts',
    confidentialityDisclaimer: 'Vertraulichkeit und Haftungsausschluss',
    workmapAssessment: 'Workmap-Bewertung',
    actualScore: 'Tatsächliche Punktzahl',
    resumeScoring: 'Lebenslaufbewertung',
    excellent: 'Ausgezeichnet',
    high: 'Hoch',
    medium: 'Mittel',
    low: 'Niedrig',
    glossaryIndex: 'Glossary/Index',
    definitionOfWorkMapSkill: 'Definition einer Workmap-Kompetenz',
    definitionOfVideoInterviewParameter: 'Definition der Parameter des Videointerviews',
    candidateSummary: 'Übersicht über den Bewerber',
    notAvailable: 'Nicht verfügbar',

  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND': 'Bitte schließen Sie Ihr Videointerview ab, um die Bewertungen zu sehen.',
    '404_ASSESSMENT_DATA_NOT_READY': 'Ihr Videointerview wird gerade ausgewertet. Bitte kommen Sie in Kürze wieder!',
    '404_CANDIDATE_NOT_AVAILABLE': 'Sie haben noch kein Videointerview absolviert. Bitte kommen Sie wieder, nachdem Sie Ihre Videointerviewantwort eingereicht haben.',
    default: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
  },
  companyDetails: {
    company: 'Unternehmen',
    about: 'Über',
    experience: 'Erfahrung',
    apply: 'Bewerben',
    description: 'Beschreibung',
    viewJobDetailText: 'Stellendetails anzeigen',
    readMore: 'Mehr lesen',
    readLess: 'Weniger lesen',
  },
  dashboardPanel: {
    dashboard: 'Dashboard',
    hey: 'Hallo',
    morningGreeting: 'Guten Morgen',
    afternoonGreeting: 'Guten Tag',
    eveningGreeting: 'Guten Abend',
    upgrade: 'Upgrade',
    support: 'Support',
    signOut: 'Abmelden',
    collapse: 'Reduzieren',
    faqs: 'FAQ',
    company: 'Unternehmen',
    role: 'Funktion',
    status: 'Status',
    action: 'Aktion',
    date: 'Datum',
    recordNow: 'Jetzt aufzeichnen',
    responded: 'Beantwortet',
    processing: 'In Bearbeitung',
    viewDetails: 'Details anzeigen',
    personalInfo: 'Persönliche Informationen',
    introVideo: 'Intro-Video',
    score: 'Punktzahl',
    email: 'E-Mail-Adresse',
    phone: 'Telefon',
    summary: 'Übersicht',
    topSkill: 'Top-Kompetenzen',
    workmapScore: 'Workmap-Punktzahl',
    aiVideoScore: 'KI-Videobewertung',
    introText1: 'Machen Sie sich bereit für spannende Einblicke. Beginnen Sie mit der Aufzeichnung',
    introText2: 'Ihres Intro-Videos.',
    recordVideo: 'Video aufzeichnen',
    settings: 'Einstellungen',
  },
  candidatePortalSettings: {
    settings: 'Einstellungen',
    publicProfile: 'Öffentliches Profil',
    profileVisibility: 'Dein Profil ist für alle sichtbar.',
    closeAccount: ' Konto schließen',
    primaryConfirmationText: 'Es tut uns leid, dass Sie gehen möchten. Sind Sie sicher, dass Sie Ihr Konto schließen möchten?',
    secondaryConfirmationText: 'Dies würde alle Ihre Daten und alle möglichen simulierten Interviews, auf die Sie geantwortet haben, löschen. Bitte beachten Sie, dass sich dadurch Ihr Interesse an jeder Bewerbung, die Sie eingereicht haben, nicht zurückzieht.',
    reasoningHeading: ' Sagen Sie uns bitte, warum Sie Ihr Konto schließen:',
    primaryFeedbackText: 'Wir schätzen Ihr Feedback, möchten jedoch darauf hinweisen, dass wir keine Rückmeldung zu Kommentaren bezüglich Ihres Kontos, die über dieses Formular eingereicht werden, geben können. Wenn Sie eine Frage oder Anfrage bezüglich Ihres Kontos haben, die Aufmerksamkeit erfordert, kontaktieren Sie uns bitte direkt.',
    clickHere: 'Hier klicken',
    buttonSectionPrimarytext: 'Bitte bestätigen Sie die Schließung Ihres Kontos.',
    deleteButtonText: 'Ja, ich bin sicher.',
    cancelButtonText: 'Nicht jetzt',
    modalConfirmtext: 'Diese Aktion führt zur Löschung/Ihrem Rückzug jeder Bewerbung, die Sie in der Vergangenheit über unsere Plattform eingereicht haben. Bitte bestätigen Sie, um fortzufahren',
    confirm: ' Bestätigen',
  },
  purchaseBanner: {
    heading: 'Führen Sie ein Upgrade durch, um Ihren ausführlichen KI-Interviewbericht einzusehen.',
    subHeading: 'Gehen Sie selbstsicher in die nächste Interviewrunde.',
    launchAt: 'Startangebot bei',
    only: 'Nur',
    year: 'Jahr',
  },
  additionalFormField: {
    chooseFileBtn: 'Datei auswählen',
    pleaseChooseAFileText: 'Bitte wählen Sie eine Datei aus.',
  },
  domainQuestion: {
    domainAssessment: 'Domänenbewertung',
    of: 'von',
    submit: 'Einreichen',
    questions: 'Fragen',
    answers: 'Antworten',
    submittedAssessment: 'Sie haben die Domainbewertung eingereicht.',
    autoRedirect: ' Automatische Weiterleitung zum nächsten Schritt in 3 Sekunden...',
  },
  quitInterviewModal: {
    confirmQuit: 'Bestätigen Sie das Beenden des Vorstellungsgesprächs',
    areYouSure: 'Sind Sie sicher, dass Sie das Vorstellungsgespräch abbrechen möchten?',
    confirm: 'Bestätigen',
    cancel: 'Stornieren',
  },
  processingVideo: {
    instruction1: 'Dein Video wird bearbeitet...',
    instruction2: 'Bitte warten',
    instruction3: 'Es dauert nur wenige Sekunden ...',
    instruction4: 'Sei geduldig.',
    noAudioFound: 'Kein Ton gefunden. Bitte mit klarem Ton neu aufnehmen. Bitte überprüfen Sie Ihren Mikrofonanschluss.',
  },
  audioError: {
    message: 'Wir bedauern, dass beim Transkribieren Ihres Videos ein Fehler aufgetreten ist. Bitte überprüfen Sie Ihr Audiosignal und versuchen Sie es erneut.',
  },
};
