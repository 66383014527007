import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { AiFillWarning } from 'react-icons/ai';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import LoadingComponent from '../components/LoadingComponent';
import JobHelmet from '../components/JobHelmet';
import i18n from '../locales/i18n';
import { ReactComponent as LocationImg } from '../assets/locationCompanydetails.svg';
import { ReactComponent as RoleTypeImg } from '../assets/roleTypeCompanydetails.svg';
import { ReactComponent as Arrow } from '../assets/ApplyNowArrow.svg';
import Brand from '../assets/NewBrandDark.svg';
import styles from '../css/CompanyDetails.module.scss';
import { ReactComponent as DownIcon } from '../assets/ArrowDown.svg';
import { LANGUAGES } from '../utils/constants';

const QrModal = (props) => {
  const {
    qrImage,
    activeId,
    currentId,
  } = props;
  return (
    <div className={activeId === currentId ? styles.modalContainer : styles.nonDisplay}>
      <img
        alt="interview qr code"
        src={qrImage}
        className={styles.qrImageStyles}
      />
    </div>
  );
};

const CompanyDetails = (props) => {
  const history = useHistory();
  const [activeJobs, setActiveJobs] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyDescription, setCompanyDescriptn] = useState('');
  const [toggle, setToggle] = useState(false);
  const [validUrl, setValidUrl] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [companyBanner, setCompanyBanner] = useState(null);
  const [showBranding, setShowBranding] = useState(true);
  const [activeModal, setActiveModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showLanguages, setShowLanguages] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const languageDropdownRef = useRef(null);
  const notificationRef = useRef(null);


  const requireReadMoreButton = true;

  const { t } = useTranslation();

  const getDataFromApi = async () => {
    const { match } = props;
    try {
      const request = await fetch(`/api/joblist/company/${match.params.companyname}`);
      const response = await request.json();
      console.log(request);
      if (!request.ok) {
        setValidUrl(!validUrl);
        setErrorMessage(response.message);
        throw new Error(request.statusText);
      }
      console.log(response);
      setActiveJobs(response.interviewDetails);
      setCompanyName(response.orgdetails.company_name);
      setCompanyLogo(response.orgdetails.company_logo_url);
      setCompanyDescriptn(response.orgdetails.description);
      setCompanyBanner(response.orgdetails.career_page_banner);
      setShowBranding(!response.orgdetails.remove_branding);
    } catch (e) {
      console.log(e);
    } finally {
      setFetchingDetails(false);
    }
  };

  const getLanguageNameByLocale = (locale, code = 'en-US') => new Intl.DisplayNames([locale], { type: 'language' }).of(code.split('-')[0]);

  const handleGlobalClick = () => {
    if (languageDropdownRef.current) {
      setShowLanguages(true);
    } else {
      setShowLanguages(false);
    }
    languageDropdownRef.current = null;
    notificationRef.current = null;
  };

  const handleLanguageChange = (code) => {
    setSelectedLanguage(code);
    setShowLanguages(false);
  };

  const DEFAULT_LANGUAGE = 'en-US';

  const detectUserLanguage = () => {
    const browserLanguage = navigator.language;
    // Check for exact match
    const exactMatch = LANGUAGES.find(lang => lang.code === browserLanguage);
    if (exactMatch) return exactMatch.code;
    // Check for base language match (e.g. 'en' from 'en-US')
    const baseMatch = LANGUAGES.find(
      lang => lang.code.split('-')[0] === browserLanguage.split('-')[0],
    );
    if (baseMatch) return baseMatch.code;
    return DEFAULT_LANGUAGE;
  };

  useEffect(() => {
    getDataFromApi();

    const savedLanguage = sessionStorage.getItem('LANGUAGE');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    } else {
      const detectedLanguage = detectUserLanguage();
      setSelectedLanguage(detectedLanguage);
    }

    window.addEventListener('click', handleGlobalClick);
    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };

  }, []);  //eslint-disable-line

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    sessionStorage.setItem('LANGUAGE', selectedLanguage);
  }, [selectedLanguage]);

  const renderFooterBanner = bannerLink => (
    <div className={styles.footerBanner}>
      <img src={bannerLink} alt={companyName} />
    </div>
  );

  const renderContent = () => {          // eslint-disable-line
    return (
      <div className="w-100">
        <div className={styles.jobCardContainer}>
          <p className={styles.availableJobTitle}>
            {`${activeJobs.filter(job => !job.is_private).length} Available Jobs`}
          </p>
          {/* Display grid card */}
          <div className="fluid">
            <Row className={styles.gridCard}>
              {
                activeJobs.map(job => (
                  !job.is_private && (
                    <Col xs={12} md={6} lg={6} xl={4} className="d-xl-flex d-block justify-content-center mb-4" key={Math.random()}>
                      <div className={styles.card}>
                        <div>
                          <span className={` ${styles.cardHeading}`}>{job.interviewname}</span>
                          <div className="flex-wrap">
                            {
                              job.cityname && job.countryname && (
                                <div className={`d-flex justify-content-between  ${styles.cardPlace}`}>
                                  <LocationImg className="mr-1" stroke="#6196FE" />
                                  {`${job.cityname}, ${job.countryname}`}
                                </div>
                              )
                            }
                            {
                              job.job_location && (
                                <div className={`d-flex justify-content-between  ${styles.cardPlace}`}>
                                  {job.job_location}
                                </div>
                              )
                            }
                            {
                              job.interview_role_type && (
                                <>
                                  <div className={`d-flex justify-content-between ${styles.cardPlace}`}>
                                    <RoleTypeImg className="mr-1" />
                                    {job.interview_role_type}
                                  </div>
                                </>
                              )
                            }
                          </div>
                          <p className={styles.cardDiscriptionTxt}>{t('companyDetails.description')}</p>
                          {
                            job.interview_description && (
                              <>
                                <p className={` ${styles.cardDescription}`}>
                                  {
                                    ReactHtmlParser(job.interview_description)
                                  }
                                </p>
                                <span type="button" role="button" tabIndex={0} onKeyDown={() => history.push(`/interview/${job.interviewid}`)} onClick={() => history.push(`/interview/${job.interviewid}`)} className={styles.viewJobDetailsText}>{t('companyDetails.viewJobDetailText')}</span>
                              </>
                            )
                          }
                        </div>
                        <QrModal
                          qrImage={job.qrCodeUrl}
                          activeId={activeModal}
                          currentId={job.interviewid}
                        />
                        <div className={`d-flex mt-4 ${job.qrCodeUrl ? styles.buttonContainer : styles.buttonContainerLeftAligned}`}>
                          {/* <div>
                            <div
                            className={styles.cardExpTxt}
                            >
                            {t('companyDetails.experience')}
                            </div>
                            <div className={styles.cardExpValue}>Fresher</div>
                          </div> */}
                          {
                            job.qrCodeUrl && (
                              <button
                                className={styles.cardApplyBtn}
                                type="button"
                                onClick={() => setActiveModal(activeModal ? null : job.interviewid)}
                              >
                                {activeModal === job.interviewid ? 'Close' : 'Qr Code'}
                                {activeModal !== job.interviewid ? <Arrow /> : ''}
                              </button>
                            )
                          }
                          <button className={styles.cardApplyBtn} type="button" onClick={() => history.push(`/interview/${job.interviewid}`)}>
                            {t('companyDetails.apply')}
                            <Arrow />
                          </button>
                        </div>
                      </div>
                    </Col>
                  )
                ))
              }
            </Row>
          </div>
        </div>
        {
          companyBanner ? (
            renderFooterBanner(companyBanner)
          ) : (
            <div className={styles.defaultBanner}>
              <a href="https://interviewer.ai/" target="_blank" rel="noopener noreferrer">
                <img src="https://company-banner-career-page.s3.amazonaws.com/career_page_default_banner.png" alt="banner" />
              </a>
            </div>
          )
        }
        {/* Footer */}
        <div className="brandPoweredBy">
          {
            showBranding && (
              <>
                <div className="poweredBy">
                  <span>Powered By</span>
                </div>
                <div className="poweredByImage">
                  <img src={Brand} alt="Interviewer.AI brand logo" className="mb-4" />
                </div>
              </>
            )
          }
        </div>
      </div>
    );
  };
  if (fetchingDetails) {
    return <LoadingComponent />;
  }
  const renderClientImage = (img, isDesktop) => {   // eslint-disable-line
    return (
      <div style={{
        borderRadius: isDesktop ? '10px' : '5px',
        padding: isDesktop ? '' : '0rem .1rem',
      }}
      >
        <div
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            minWidth: isDesktop ? '100px' : '60px',
            minHeight: isDesktop ? '100px' : '60px',
            maxWidth: isDesktop ? '100px' : '60px',
            maxHeight: isDesktop ? '100px' : '60px',
            borderRadius: isDesktop ? '9px' : '5px',
          }}
        />
      </div>
    );
  };

  const renderFlag = (selectedLang) => {
    const language = LANGUAGES.find(lang => lang.code === selectedLang);
    return (
      language && (
        <img src={language.flag} className={styles.FlagImg} alt={language.alt} />
      )
    );
  };

  const LanguageDropdown = () => (
    // eslint-disable-next-line
    <div className={styles.languageChangeDropdown} onClick={() => setShowLanguages(!showLanguages)} role="button">
      <div
        style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
      >
        {renderFlag(selectedLanguage)}
        <div style={{ display: 'flex' }}>
          <DownIcon />
        </div>
      </div>

      {showLanguages && (
        <ul className={styles.languageDropdown} ref={languageDropdownRef}>
          {LANGUAGES.map(({ code, flag, labelKey }) => (
            <li
              key={code}
              className={styles.languageDropdownContent}
              onClick={() => handleLanguageChange(code)}
              onKeyDown={() => handleLanguageChange(code)}
              // eslint-disable-next-line
              role="button"
              tabIndex={0}
            >
              <img src={flag} className={styles.FlagImg} alt={`${labelKey}Flag`} />
              <div>{getLanguageNameByLocale(selectedLanguage, code)}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );


  return validUrl ? (
    <div className={styles.mainContainer}>
      <JobHelmet
        jobName=""
        companyName={companyName}
        companyLogoUrl={companyLogo}
        location={window.location}
        source="career-page"
        description={activeJobs.length}
      />
      <div className={styles.backgroundHeader} />
      <div className={styles.languageDropdownWrapper}>
        <LanguageDropdown />
      </div>
      <div className={styles.companyDetailsContent}>
        <div className={styles.contentSection}>
          <div className={`d-flex align-items-center ${styles.stickyHeader}`}>
            <div className={styles.companyLogoSection}>
              {renderClientImage(companyLogo, true)}
            </div>
            <div className="d-flex flex-column  justify-centent-center">
              <span className={styles.companyName}>{companyName}</span>
              <span className={styles.companyText}>
                {t('companyDetails.company')}
              </span>
            </div>
          </div>
          {companyDescription && (
            <div className={styles.discriptionDiv}>
              <div className={styles.aboutHeading}>
                {t('companyDetails.about')}
              </div>
              {/* Discription part */}
              <p
                className={`apply-screen__description
                      ${requireReadMoreButton
                  && 'apply-screen__description-blur'
                  }
                      ${toggle && 'apply-screen__description-expand'} ${styles.companyDiscription
                  }`}
              >
                {' '}
                {/* eslint-disable-line */}
                {ReactHtmlParser(companyDescription)}
              </p>
              <span
                role="button"
                tabIndex="0"
                className={styles.readmoreText}
                onKeyDown={() => setToggle(!toggle)}
                onClick={() => setToggle(!toggle)}
              >
                {' '}
                {/* eslint-disable-line */}
                {
                  t(
                    toggle
                      ? 'companyDetails.readMore'
                      : 'companyDetails.readMore',
                  )
                }
              </span>
            </div>
          )}
        </div>
      </div>
      {renderContent()}
    </div>
  ) : (
    <div
      className={`d-flex justify-content-center align-items-center ${styles.errorContainer}`}
    >
      <div className={`${styles.errorCard}`}>
        <div className="d-flex justify-content-center mb-3">
          <AiFillWarning size="70" />
        </div>
        <p className={styles.errorTxt1}>Oh No!</p>
        <p className={styles.errorTxt2}>{errorMessage}</p>
      </div>
    </div>
  );
};

CompanyDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyname: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

QrModal.propTypes = {
  qrImage: PropTypes.string.isRequired,
  activeId: PropTypes.string.isRequired,
  currentId: PropTypes.string.isRequired,
};

export default withTranslation()(CompanyDetails);      // eslint-disable-line
