const config = {
  MIXPANEL_TOKEN: 'ee1b1cceae1011034dc96f47681961ac',
  JOBS_NEARBY_INTERVIEW: 'cf833137-bacd-42ca-9478-8c52697fbf78', // barista
  INSIGHT_PRO_INTERVIEW: 'cd98d9a9-ff3c-4990-bba4-d38fadcd0986',
  HOSPITALITY_INTERVIEW: '901993a0-82a7-42d9-b395-2d38c8755856',
  INTRODUCTION_INTERVIEW: 'abbb5844-9c97-48d9-959c-a35a7f5c5223',
  SENTRY_DSN: 'https://b686d7536053418ea10e710042e64aa3@o384120.ingest.sentry.io/5215220',
  PERSONALITY_TEST_FLOW_INTERVIEW: 'f2d7e708-accc-4585-8d11-fb8ed027dfdf',
  API_ENDPOINT: 'http://localhost:5000',
  AUTH0: {
    domain: 'candidates-dev.eu.auth0.com',
    clientID: 'xGZ45Q9TxWXnJmOpAC4Ic10KegHv9IGy',
  },
  POST_INTERVIEW_REDIRECTS: {
    'Search4Maids API': 'http://search4maid.solutionforest.net/interview/result',
  },
  WSG_ORGANIZATION_ID: ['4838ec0d-d785-4250-a64d-bc9612a8c36f'],
  DEFAULT_CANDIDATE_IMAGE: 'https://dev-interviewer-ai-assets.s3.amazonaws.com/Brand.jpg',
  VIDEOS_BUCKET_URL: 'https://dev-interviewer-ai-videos.s3.ap-southeast-1.amazonaws.com',
  SLACK_NOTIFICATION: 'https://hooks.slack.com/services/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',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_1VjOFXlCnhCslGingEm1e5Cz',
  CANDIDATE_PLANS: {
    basic: {
      indianPlan: {
        amount: '999',
      },
      usPlan: {
        amount: '12',
      },
    },
  },
  // HIDING JOB DESCRIPTION AND JOBTYPE WITH THANK YOU PAGE SUPPORT EMAIL
  HIDE_JOBTYPE_AND_SUPPORT_EMAIL: ['d3b4de89-07ac-44df-af1a-0235b3cc8470'],
  HIDE_THANK_YOU_PAGE_INS_FOR_NUS: ['d3b4de89-07ac-44df-af1a-0235b3cc8470'],
  // DISPLAYING COUNTDOWN TIMER FOR YESBANK ON RECORD AGAIN
  YESBANK_ORGANIZATION_ID: ['eba123b2-0d2f-4acb-b87d-5f74ddbf293b', 'd3b4de89-07ac-44df-af1a-0235b3cc8470'],
  HIDE_RESPONSE_TIME_TEXT: ['d3b4de89-07ac-44df-af1a-0235b3cc8470'],
  VIDEO_INTERVIEW_GATE_PROMPT_ORGS: ['26d9ac49-db17-4414-887c-84e3c039d7c3', 'f37aa9b7-2694-4650-8137-93fb40e69704'],
  HIDE_ORGANIZATION_LOGO: ['d3b4de89-07ac-44df-af1a-0235b3cc8470'],
  INTERVIEWERAI_DOMAIN: 'https://interviewer.ai/',
  CAREER_SIDE_INTERVIEWERAI_DOMAIN: 'https://careers.interviewer.ai/',
  INTERVIEWERAI_DASHBOARD_DOMAIN: 'https://dashboard.interviewer.ai/',
  INTERVIEWERAI_RESUME_BUILDER_DOMAIN: 'https://resume.interviewer.ai/',
  ENTERPRISE_CLIENTS: ['d3b4de89-07ac-44df-af1a-0235b3cc8470'],
  AIA_ORG_ID: ['1922015b-cec6-4d22-82d7-f98086557727', 'adbd3694-8d88-415d-966c-909ec6045b7c'],
  AIA_API_ENDPOINT: 'https://imo2.aia.id/app/gateway/iRecruite/3.0/recruit/v1',
  AIA_ORGANISATION_ID: '1922015b-cec6-4d22-82d7-f98086557727',
  HIDE_RETAKE_AFTER_LIMIT: ['d3b4de89-07ac-44df-af1a-0235b3cc8470', 'adbd3694-8d88-415d-966c-909ec6045b7c', '1922015b-cec6-4d22-82d7-f98086557727'],
  AIA_API_KEY: '15c0f6b5-8ea2-454c-a50e-c10fab7eb0da',
  HIDE_CANDIDATE_PORTAL_LINK: ['1922015b-cec6-4d22-82d7-f98086557727', 'adbd3694-8d88-415d-966c-909ec6045b7c', '4b28e81b-e88e-4521-bb44-a823037f0623'],
  DEMO_AVATAR_QUESTIONS: [
    'https://avatar-questions-dev.s3.ap-southeast-1.amazonaws.com/demo-question.mp4',
    'https://avatar-questions-dev.s3.ap-southeast-1.amazonaws.com/6fc397d3-30f9-4acb-b22b-0f0b665cbefc.mp4',
    'https://avatar-questions-dev.s3.ap-southeast-1.amazonaws.com/2e9b35b9-03c6-4705-9410-d567193be9f4.mp4',
    'https://avatar-questions-dev.s3.ap-southeast-1.amazonaws.com/9fa813e2-6650-4392-94dd-ad636720b604.mp4',
  ],
  AVATAR_QUESTION_ORG: [],
  AVATAR_QUESTION_INTERVIEW: '1928047b-5adc-4fc3-97fd-5b246b75760d',
  AVATAR_QUESTIONS: [
    '6fc397d3-30f9-4acb-b22b-0f0b665cbefc',
    '2e9b35b9-03c6-4705-9410-d567193be9f4',
    '9fa813e2-6650-4392-94dd-ad636720b604',
  ],
  APPLICATION_ID: 'b9f70a5e-f001-42f5-bc8c-4a40a1ea0996',
  APPLICATION_REGION: 'us-east-1',
  guestRoleArn: 'arn:aws:iam::380359251827:role/RUM-Monitor-us-east-1-380359251827-7388327871071-Unauth',
  identityPoolId: 'us-east-1:228711fa-cf3b-4e65-9d33-45fee6703d8c',
  CANDIDATE_EXTRA_INFORMATION: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  SOLVE_CUBE_UI: '',
  ENVIRONMENT: 'Development',
  CAREER_GRIT_ORG_ID: 'd54424b3-6da3-4e8d-98dd-e21efb1e368c',
};

export default config;
