import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import pl from './pl';
import zh from './zh';
import es from './es';
import id from './id';
import fr from './fr';
import vi from './vi';
import de from './de';
import ptBr from './pt_BR';
// import th from './th';
import ar from './ar';
import hi from './hi';
import pt from './pt';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    lng: 'en-US',
    fallbackLng: 'en-US',
    debug: true,
    resources: {
      'en-US': {
        translation: en,
      },
      'zh-CN': {
        translation: zh,
      },
      pl: {
        translation: pl,
      },
      'es-ES': {
        translation: es,
      },
      'id-ID': {
        translation: id,
      },
      'vi-VN': {
        translation: vi,
      },
      'fr-FR': {
        translation: fr,
      },
      'de-DE': {
        translation: de,
      },
      'pt-BR': {
        translation: ptBr,
      },
      'th-TH': {
        translation: en, // temporary use en because our font doesn't support Thai
      },
      'ar-SA': {
        translation: ar,
      },
      'hi-IN': {
        translation: hi,
      },
      'pt-PT': {
        translation: pt,
      },
    },
  });

export default i18n;
