import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import CandidateStore from '../../../stores/CandidateStore';
import CandidateActions from '../../../actions/CandidateActions';
import LoadingComponent from '../../../components/LoadingComponent';
import ErrorPage from '../../ErrorPage';
import CandidateResponse from '../../../components/CandidateVideoResponse';
import CandidateScore from '../../../components/CandidateScore';
import AppDrawer from '../../../components/AppDrawer';
import {
  CANDIDATE_PURCHASE_BANNER_INR,
  CANDIDATE_PURCHASE_BANNER_USD,
} from '../../../utils/constants';
import styles from '../../../css/CandidateDetailsNew.module.scss';
import { ReactComponent as OverallScoreIcon } from '../../../assets/overallScoreIcon.svg';

import {
  dateFormatDdMmmYyyy,
  // mxTrackEvent,
  isMobile,
  currentCountryUsingIp,
} from '../../../utils/utils';
import CandidateDetailsMobile from './CandidateDetailsMobile/CandidateDetailsMobile';

// eslint-disable-next-line no-unused-vars
const PurchaseBanner = ({ email }) => {
  const getCurrentCountry = async () => {
    const country = await currentCountryUsingIp();
    return country;
  };

  const history = useHistory();
  const image = getCurrentCountry() === 'india' ? CANDIDATE_PURCHASE_BANNER_INR : CANDIDATE_PURCHASE_BANNER_USD;
  const handleReadyForPurchase = () => {
    // mxTrackEvent('Clicked on learn more', {
    //   email,
    // });
    history.push('/user/billing');
  };

  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        minWidth: '100%',
        minHeight: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      <button
        type="button"
        className={styles.learnMoreButton}
        onClick={handleReadyForPurchase}
      >
        Learn more
      </button>
    </div>
  );
};

const CandidateDetailsNew = (props) => {
  const { match } = props; //eslint-disable-line
  const { interviewId, candidateId } = match.params; //eslint-disable-line
  const purchaseBannerRef = useRef(null);

  const [candidateProfile, setCandidateProfile] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLocked, setIsLocked] = useState(true);
  const [isCoaching, setIsCoaching] = useState(false);
  const [isReportDownloading, setIsReportDownloading] = useState(false);
  const [isMobileView, setIsMobileView] = useState(isMobile());

  const handleStoreChange = (store) => {
    const {
      candidateAssessmentsError,
      candidateAssessments,
      isFetchingCandidateAssessment,
      candidateSubscription,
      isCoachingCandidate,
      reportDownloadProcessing,
    } = store;
    setIsCoaching(isCoachingCandidate);
    const { is_locked: isLocked } = candidateSubscription; //eslint-disable-line
    if (candidateAssessments[candidateId]
      && !isFetchingCandidateAssessment[candidateId]
      && !candidateAssessmentsError) {
      const candidate = candidateAssessments[candidateId];
      setCandidateProfile(candidate);
    }

    setError(candidateAssessmentsError);

    setIsLocked(isLocked);
    setIsLoading(isFetchingCandidateAssessment);
    setIsReportDownloading(reportDownloadProcessing);
  };

  useEffect(() => {
    CandidateStore.listen(handleStoreChange);
    CandidateActions.fetchSubscription();
    CandidateActions.fetchCandidateCoachingStatus();

    const store = CandidateStore.getState();
    if (store.candidateAssessments[candidateId]) {
      handleStoreChange(store);
    } else {
      CandidateActions.fetchCandidateAssessment(candidateId, interviewId);
    }
    const handleResize = () => setIsMobileView(isMobile());
    window.addEventListener('resize', handleResize);

    return () => {
      CandidateStore.unlisten(handleStoreChange);
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line
  }, []); // eslint-disable-next-line


  const handleCompanyLogoRenderer = companyLogoUrl => (
    <div
      style={{
        backgroundImage: `url(${companyLogoUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        minWidth: '100%',
        minHeight: '5em',
        maxWidth: '100%',
        maxHeight: '5em',
      }}
    />
  );

  if (isLoading || !candidateProfile) {
    return <LoadingComponent type="rise" />;
  }

  if (!isLoading && error) {
    console.log(error); //eslint-disable-line
    Sentry.captureException(error);
    return <ErrorPage errorCode={error} />;
  }
  return (
    <React.Fragment>
      {
        isMobileView ? (
          <CandidateDetailsMobile
            candidateProfile={candidateProfile}
            interviewId={interviewId}
            candidateId={candidateId}
            isLocked={isLocked}
            isCoaching={isCoaching}

          />
        ) : (
          <div className={styles.candidateDetailContainer}>
            <div className={styles.appDrawerConatiner}>
              <AppDrawer />
            </div>
            <div className={styles.candidateInterviewSection}>
              <div className={styles.basicInfoBar}>
                <div className={styles.overAllScoreConatiner}>
                  <OverallScoreIcon />
                  <p className={styles.overAllScore}>
                    {candidateProfile.overallInterviewScore}
                    %
                  </p>
                  <p className={styles.overallLabel}>Overall Score</p>
                </div>
                <div style={{ padding: '1rem' }}>
                  {handleCompanyLogoRenderer(candidateProfile.companyLogoUrl)}
                </div>
                <div className={styles.basicInfoContainer}>
                  <div className={styles.centerElement}>
                    <div className="flexCenter gap-1-em">
                      <div className={styles.jobDetails}>
                        <span>
                          {candidateProfile.interviewname}
                        </span>
                        <span>
                          {candidateProfile.companyName}
                        </span>
                        <span>
                          {candidateProfile.location}
                        </span>
                      </div>

                    </div>
                  </div>
                  <div className={styles.centerElement}>
                    <div className={styles.appliedOnSection}>
                      <span className={styles.greyLabel}>
                        Applied date:
                      </span>
                      <span>{dateFormatDdMmmYyyy(candidateProfile.appliedDate)}</span>
                    </div>
                  </div>
                  <div className={styles.centerElementEnd}>
                    <button
                      type="button"
                      onClick={() => {
                        if (!(!isLocked || isCoaching)) {
                          // mxTrackEvent("Interest expressed in download reports'", {
                          //   user: candidateProfile.email,
                          // });
                          purchaseBannerRef.current.scrollIntoView({ behavior: 'smooth' });
                          return;
                        }
                        CandidateActions.downloadCandidateReport(
                          interviewId,
                          candidateId,
                          candidateProfile.interviewname,
                        );
                      }
                      }
                      disabled={isReportDownloading}
                      className={styles.downloadReportButton}
                    >
                      {
                        isReportDownloading ? 'Downloading...' : 'Download Report'
                      }
                    </button>
                  </div>
                </div>

              </div>


              <div>
                <CandidateResponse
                  interviewId={interviewId}
                  candidateId={candidateId}
                />
              </div>
              <div className={styles.candidateScoreContainer}>
                {
                  (!isLocked || isCoaching) ? (
                    <CandidateScore
                      aiScore={candidateProfile.scores.aiScores}
                      workmapScore={candidateProfile.scores.workmap}
                      resume={candidateProfile.scores.resume}
                      resumeMandatory={candidateProfile.resumeMandatory}
                      workmapEnabled={candidateProfile.workmapEnabled}
                      totalCommunicationScore={candidateProfile.totalCommunicationScore}
                      resumeGenAiDetails={candidateProfile.resumeDetails}
                    />
                  ) : (
                    <div ref={purchaseBannerRef} className={styles.bannerContainer}>
                      <PurchaseBanner email={candidateProfile.email} />
                    </div>
                  )
                }

                {/* { (isLocked || !isCoaching) ? (
                  <div ref={purchaseBannerRef} className={styles.bannerContainer}>
                    <PurchaseBanner/>
                  </div>
                ) : (
                  <CandidateScore
                    aiScore={candidateProfile.scores.aiScores}
                    workmapScore={candidateProfile.scores.workmap}
                    resume={candidateProfile.scores.resume}
                    resumeMandatory
                />
                )
                } */}

              </div>

            </div>
          </div>
        )
      }

    </React.Fragment>
  );
};


PurchaseBanner.propTypes = {
  email: PropTypes.string.isRequired,
};


export default withTranslation()(withRouter(CandidateDetailsNew));
